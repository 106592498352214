<template>
<v-list outlined style="border-radius: 20px; " class="mb-4">
    <v-list-item>
        <v-list-item-content class="pb-0" style="display: inline">
            <!--<h5 v-if="value.title" style="display: inline; vertical-align: middle;">{{ value.title }}</h5>
            <h5 v-else style="display: inline; vertical-align: middle;">{{ $t("location_comp.location") }}</h5>-->
            <h5 v-if="index>=0" style="display: inline; vertical-align: middle;">Τοποθεσία {{ index + 1 }}</h5>
            <div v-if="index>=0" style="display: inline" class="ml-4">
                <v-btn 
                    :loading="loading_setDefaultLocation"
                    depressed
                    color="primary"       
                    small             
                    v-if="!value.primary_location"
                    @click="$emit('set-default', index)"                    
                >
                    <v-icon left>
                        mdi-map-marker-plus
                    </v-icon>
                    {{ $t("location_comp.set as default") }}
                </v-btn>  
                <v-chip
                    class="ma-2"                    
                    color="green"
                    text-color="white"                    
                    v-else
                >
                    <v-avatar left>
                        <v-icon>mdi-map-marker-check</v-icon>
                    </v-avatar>
                    {{ $t("location_comp.default location") }}
                </v-chip>
            </div> 

        </v-list-item-content>
    </v-list-item> 
    <v-list-item>
        <v-list-item-content class="pb-0 mt-4">                
            <v-text-field 
                label="Title" 
                v-model="value.title"    
                :error-messages="titleValState"         
                :validate-on-blur="false"
                class="col-12 p-0 pr-4"
                style="padding: 0px 0px; font-size: 1.5em"                            
            />
        </v-list-item-content>
    </v-list-item>         
    <v-list-item>
        <v-list-item-content class="pb-0">
            <span>{{ $t("location_comp.drag marker to correct") }}</span>
        </v-list-item-content>                                        
    </v-list-item> 
    <v-list-item>
        <v-list-item-content class="pb-0">
            <!--<google-map :pois="pois" :draggable="true" :zoom="18" @ondrag="markerdragged" />-->
            <GoogleMapX @onresult="addressChanged" :pinCoords="{lat: value.latcoord, lng: value.lngcoord}" />
        </v-list-item-content>                                        
    </v-list-item>  
    <v-list-item v-if="!coordsValState">
        <v-list-item-content class="pb-0">
            <v-alert                
                color="red"
                dark
                type="error"
            >
            {{ $t("location_comp.map selection required") }}
            </v-alert>
        </v-list-item-content>                                        
    </v-list-item> 
    <!--<v-list-item>
        <v-list-item-content class="pb-0">
            <div>
                <v-btn 
                    depressed
                    color="primary"
                    x-small
                    v-if="!value.primary_location"
                    @click="$router.push('/')"                    
                >
                    <v-icon left>
                        mdi-map-marker-check
                    </v-icon>
                    {{ $t("location_comp.set as default") }}
                </v-btn>        
            </div>            
        </v-list-item-content>
    </v-list-item>     -->    
    <v-list-item>
        <v-list-item-content class="pb-0 pt-10">
            <h6>Address</h6>
        </v-list-item-content>
    </v-list-item> 
    <v-list-item>
        <v-list-item-content class="pb-0 mt-3">            
            <v-text-field 
                label="Street" 
                v-model="value.street_address"                    
                :error-messages="street_addressValState"                
                class="col-12 p-0 pr-4"                
                style="padding-inline: 0px;"  
            />
            <v-text-field 
                label="Postal Code" 
                v-model="value.postal_code"    
                :error-messages="postal_codeValState"
                :validate-on-blur="false"
                class="col-4 p-0 pr-4"
                style="padding-inline: 0px;"                        
            />
            <v-text-field 
                label="City" 
                v-model="value.municipality"    
                :error-messages="municipalityValState"
                :validate-on-blur="false"
                class="col-8 p-0 pr-4"
                style="padding-inline: 0px;"           
            />
            <v-text-field 
                label="State" 
                v-model="value.prefecture"    
                :error-messages="prefectureValState"
                :validate-on-blur="false"
                class="col-12 p-0 pr-4"
                style="padding-inline: 0px;"  
            />
            <v-textarea
                label="Extra Information" 
                placeholder="Doorbell Name, Entrance Instructions e.t.c."
                v-model="value.extra_info"    
                :rules="[]"
                :validate-on-blur="false"
                class="col-12 p-0 pr-4"
                style="padding-inline: 0px;"  
                mu
            />
        </v-list-item-content>                                        
    </v-list-item>    
    <!-- <v-list-item>
        <v-list-item-content class="pb-0">
            <h6>Contact</h6>
        </v-list-item-content>
    </v-list-item>                              
    <v-list-item>                                        
        <v-list-item-content class="pb-0">                                            
            <v-text-field 
                label="Phone No." 
                v-model="value.phoneno"    
                :rules="[]"
                :validate-on-blur="false"
                class="col-6 p-0 pr-4"
                style="padding: 0px 0px;"
            />                                   
            <v-text-field 
                label="Email" 
                v-model="value.email"    
                :rules="[]"
                :validate-on-blur="false"
                class="col-6 p-0 pr-4"
                style="padding: 0px 0px;"
            />
            
        </v-list-item-content>
    </v-list-item>  -->
</v-list>      
</template>

<script>
import { api } from "src/api/index";
import moment from "moment";
import axios from "axios";
import GoogleMap from "@/components/core/GoogleMap";
import GoogleMapX from "@/components/core/GoogleMapX";

import _ from "lodash";

import { mapGetters, mapActions } from "vuex";

export default {
    // metaInfo: {
    //     // title will be injected into parent titleTemplate
    //     title: 'Professional Setip'
    // },    
    components: {        
        GoogleMap,
        GoogleMapX    
    },
    model: {
        prop: "value",
        event: "change"
    },
    // props: ['ttindex', 'modelValue'],
    //props: ['index', 'value'],

    props: {
        index: Number,
        loading_setDefaultLocation: Boolean,
        value: {
            type: Object,
            default: () => ({
                code: null,
                primary_location: false,
                position: null,
                title: undefined,
                prefecture: undefined,
                municipality: undefined,
                street_address: undefined,
                postal_code: undefined,
                extra_info: undefined,                
                // phoneno: undefined,
                // email: undefined,
                latcoord: undefined,
                lngcoord: undefined,
                valstate: undefined,
            }),
        },
        // valstate: {
        //     type: Object
        // }
    },

    // props: {
    //     ttindex: null,
    //     modelValue: null,
    // },

    
    
    data() {
        return {            
            searchaddress: null,

            code: this.value.code,
            address: this.value.address,            

            prefecture: this.value.prefecture,
            municipality: this.value.municipality,            
            street_address: this.value.street_address,
            postal_code: this.value.postal_code,
            title: this.value.title,
            // phoneno: this.value.phoneno,
            // email: this.value.email,
            extra_info: this.value.extra_info,
            latcoord: this.value.latcoord,
            lngcoord: this.value.lngcoord,        
            
            valstate: this.value.valstate || null,
            
            loading: false,
            addressresults: [],
            resultselected: false,
        }
    },    
    computed: {
        titleValState() {
            return this.value.valstate?.title?.msg;
        },
        prefectureValState() {
            return this.value.valstate?.prefecture?.msg;
        },
        municipalityValState() {
            return this.value.valstate?.municipality?.msg;
        },
        street_addressValState() {
            return this.value.valstate?.street_address?.msg;
        },
        postal_codeValState() {
            return this.value.valstate?.postal_code?.msg;
        },
        coordsValState() {
            if(this.value.hasOwnProperty("valstate")) {
                if (this.value.valstate?.hasOwnProperty("coords")) {
                    return this.value.valstate?.coords?.valid;
                }
            }                        
            return true;
        },
        // pois() {
        //     if (typeof this.address === 'object' && this.address !== null) {
        //         return [{
        //             position: {
        //                 lat: this.address.data.lat,
        //                 lng: this.address.data.lng
        //             }
        //         }]
        //     }
        //     else {
        //         return [];
        //     }
        // }
    },
    methods: {
        ...mapActions([]),
        addressChanged: function (addressInfo) {
            //alert(JSON.stringify(addressInfo));
            if(addressInfo.lat) {
                this.value.latcoord = addressInfo.lat;
            }
            if(addressInfo.lng) {
                this.value.lngcoord = addressInfo.lng;
            }

            if(addressInfo.streetaddress) {
                this.value.street_address = addressInfo.streetaddress;
            }
            if(addressInfo.municipality) {
                this.value.municipality = addressInfo.municipality;
            }
            if(addressInfo.postalcode) {
                this.value.postal_code = addressInfo.postalcode;
            }
            if(addressInfo.prefecture) {
                this.value.prefecture = addressInfo.prefecture;
            }
            // if(addressInfo.country) {
                
            // }
        },
    },
    async created() {
    },
    async mounted() {
        
    }
}
</script>
<style lang="scss" scoped>

</style>
