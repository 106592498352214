<template>
    <div class="page-wrap-">
        <v-row style="min-height: 100vh">
            <v-col cols="0" md="2" class="ma-0 pa-0 text-center " align-self="center">
            </v-col>
            <v-col cols="12" md="4" class="ma-0 pa-5 text-center " align-self="center">
                <h3 style="margin-bottom: -20px">{{ $t("Signup.complete your registration") }}</h3>                
                <img src="@/assets/images/massage-logo-w.png" alt="" class="mb-0 pb-0" /> 
                <!-- <img src="@/assets/images/flags/1x1/gr.svg" alt="" class="mb-0 pb-0" />  -->
            </v-col>
            <v-col cols="12" md="4" class="ma-0 pa-0" align-self="center">
                <div v-if=" uiState == '' || uiState == null || mobileVeriticationStates.includes(uiState) " class="session-form-hold align-self-stretch">
                        <base-card>
                            <v-progress-linear
                                :active="loading"
                                indeterminate
                                v-if="loading"                                
                                absolute
                                top
                                color="primary"
                            ></v-progress-linear>
                            <v-card-text class="text-center pb-10">
                                <div v-if="showGreeting" class="d-flex justify-space-between">
                                    
                                    <h6  class="text--disabled- font-weight-medium mb-2">
                                        {{ $t("Signup.Hi") }}, {{ firstName }}!
                                    </h6>                                    
                                    <LanguageSelector></LanguageSelector>
                                </div>
                                <div v-if="showGreeting" class="d-flex justify-space-between">                                                                        
                                    <h6 class="text--disabled- font-weight-medium mb-2">
                                        {{ $t("Signup.enter your contact info") }}
                                    </h6>                                    
                                </div>
                                <div v-if="!showGreeting" class="d-flex justify-space-between">                                                                        
                                    <h6 class="text--disabled- font-weight-medium mb-2">
                                        {{ $t("Signup.enter your contact info") }}
                                    </h6>
                                    <LanguageSelector></LanguageSelector>
                                </div>
                                <v-form class="pt-10" v-model="formValid" ref="regForm">
                                    <v-text-field 
                                        :label= "$i18n.t('Signup.Firstname')"
                                        v-model="firstName"    
                                        :rules="nameRules"
                                        :validate-on-blur="true"                                        
                                    />
                                    <v-text-field 
                                        :label="$i18n.t('Signup.Lastname')"
                                        v-model="lastName"  
                                        :rules="nameRules"  
                                        :validate-on-blur="true"                                                                  
                                    />  
                                    <v-text-field 
                                        ref="mobilefield"
                                        :label="$i18n.t('Signup.MobileNo')"
                                        v-model="mobileNo"  
                                        :rules="mobileNoRules"  
                                        :validate-on-blur="true"                                                                                            
                                        :error-messages="mobileErrorMessages"
                                        :readonly="uiState==='mobileverification_success'"
                                        :prepend-inner-icon="uiState==='mobileverification_success' ? 'mdi-check-circle-outline' : null"
                                    >                           
                                        <template v-slot:append-outer>
                                            
                                            <v-btn  
                                                v-if="uiState === 'mobileverification'"                                         
                                                class="mb-2 text-capitalize"                                                                                
                                                color="black"
                                                dark
                                                @click="verifyMobile"
                                                :loading="mobileVerificationInProgress"
                                                :disabled="uiState==='mobileverification_success'"
                                            >
                                                <v-icon left>mdi-refresh</v-icon>
                                                Resend Code
                                            </v-btn> 
                                            <v-btn  
                                                v-else
                                                class="mb-2 text-capitalize"                                                                                
                                                color="black"
                                                dark
                                                @click="verifyMobile"
                                                :loading="mobileVerificationInProgress"
                                                :disabled="uiState==='mobileverification_success'"
                                            >
                                                <v-icon left>mdi-cellphone-message</v-icon>                                                
                                                {{ $t("Signup.Verify by SMS") }}
                                            </v-btn> 
                                        </template>             
                                    </v-text-field>  
                                    <h6 v-if="uiState === ''" class="font-weight-medium">
                                        {{ $t("Signup.click button to verify mobile") }}
                                        <!-- You will receive an activation e-mail shortly, <br/>
                                        so check your inbox to complete the signup process and create your account before logging in. -->
                                    </h6>
                                    
                                    <h6 v-if="mobileVeriticationStates.includes(uiState) && uiState !== 'mobileverification_success' " class="text--disabled font-weight-medium">
                                        {{ $t("Signup.you will receive confirmation code shortly") }}
                                        <!-- You will receive an activation e-mail shortly, <br/>
                                        so check your inbox to complete the signup process and create your account before logging in. -->
                                    </h6>
                                    <h6 v-if="mobileVeriticationStates.includes(uiState) && uiState !== 'mobileverification_success' " class="text--disabled font-weight-medium">
                                        {{ $t("Signup.please enter it below") }}
                                        <!-- You will receive an activation e-mail shortly, <br/>
                                        so check your inbox to complete the signup process and create your account before logging in. -->
                                    </h6>
                                    <div v-if="mobileVeriticationStates.includes(uiState) && uiState !== 'mobileverification_success' ">
                                        <div class="ma-auto position-relative" style="max-width: 300px">
                                            <v-otp-input                                                
                                                length="6"
                                                type="number"    
                                                v-model="mobileVerificationCode"     
                                                :disabled="smscodeVerificationInProgress"
                                                @finish="verifyShortcode"                         
                                            ></v-otp-input>
                                            <v-overlay absolute :value="smscodeVerificationInProgress">
                                                <v-progress-circular
                                                indeterminate
                                                color="primary"
                                                ></v-progress-circular>
                                            </v-overlay>
                                        </div>
                                    </div>                                    
                                    <v-alert dense outlined type="error" v-if="uiState === 'mobileverification_error'">
                                        {{ $t("Signup.Invalid SMS Code") }}
                                    </v-alert>
                                    <v-alert dense outlined type="success" v-if="uiState === 'mobileverification_success'">
                                        {{ $t("Signup.Code Validated Successfully") }}
                                    </v-alert>
                                    <!-- <v-text-field
                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show ? 'text' : 'password'"
                                        name="input-10-2"
                                        label="Password" 
                                        :rules="passwordRules"
                                        ref="pwdField"
                                        v-model="password"
                                        @click:append="show = !show"        
                                        :validate-on-blur="true"                    
                                    >
                                    </v-text-field> -->
                                    <v-alert dense outlined type="error" v-if="error && error == 'mustverifymobile'">
                                        {{ $t("Signup.Please verify your mobile number to complete your registration") }}
                                        <!-- <div>There has been an unexpected error with your registration. Please retry or contact the Administrator.</div> -->
                                    </v-alert>
                                    <v-alert dense outlined type="error" v-if="error && error != 'mustverifymobile'">
                                        {{ $t("Signup.There has been an unexpected error with your registration") }}
                                        <!-- <div>There has been an unexpected error with your registration. Please retry or contact the Administrator.</div> -->
                                    </v-alert>
                                    <v-btn class="mb-4 mt-8 text-capitalize"                                     
                                        block 
                                        color="#EB8E45" 
                                        @click="completeRegistration" 
                                        dark                
                                        :loading="loading"                                              
                                    >
                                        <v-icon left>mdi-emoticon-happy-outline</v-icon>
                                        {{ $t("Signup.complete registration") }}
                                    </v-btn>
                                </v-form>
                            </v-card-text>
                        </base-card>
                </div>
                <div v-else-if=" uiState == 'alreadyactivated' " class="session-form-hold align-self-stretch">
                    <base-card>
                        <v-card-text class="text-center pb-10">
                            <h6 class="text--disabled- font-weight-medium mb-2">
                                {{ $t("Signup.user already activated") }}
                            </h6>
                        </v-card-text>
                    </base-card>
                </div>
                <div v-else-if=" uiState == 'invalidcode' " class="session-form-hold align-self-stretch">
                    <base-card>
                        <v-card-text class="text-center pb-10">
                            <h6 class="text--disabled- font-weight-medium mb-2">
                                {{ $t("Signup.Invalid Activation Link") }}
                            </h6>
                        </v-card-text>
                    </base-card>
                </div>
            </v-col>
            <v-col cols="0" md="2" class="ma-0 pa-0 text-center " align-self="center">
            </v-col>
        </v-row>
    






        <!-- <div class="session-form-hold">
            <base-card>
                <v-card-text class="text-center">
                    <img src="@/assets/images/adpoints-logo.png" alt="" />*
                    <h5>{{ header_message }}</h5>
                    <h6 class="text--disabled font-weight-medium">
                        {{ subheader_message }}
                    </h6>
                    <v-progress-circular
                        v-if="!loading"
                        :size="40"
                        :width="7"
                        color="purple"
                        indeterminate
                    ></v-progress-circular>                    
                    <v-btn class="m-4 mt-4" 
                            v-if="success"
                            block 
                            color="primary"                             
                            dark                            
                            to="/auth/login"
                        >Proceed To Login</v-btn>          
                </v-card-text>
            </base-card>
        </div> -->
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import { api } from "src/api/index";
import { fieldNotEmpty, fieldMinLength } from "src/core/vuetify-validators.js"
import LanguageSelector from "@/components/core/LanguageSelector";

export default {
    name: 'ActivateAccount',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Activate Account'
    },
    components: {
        LanguageSelector
    },
    data() {
        return {

            firstName: '',
            lastName: '',
            mobileNo: '',
            activationCode: '',
            mobileVerificationCode: '',
            mobileVerified: false,
            mobileVerificationRequested: false,
            mobileVerificationInProgress: false,
            smscodeVerificationInProgress: false,            

            // header_message: 'Activating...',
            // subheader_message: 'Please wait',

            mobileErrorMessages: [],
            mobileVeriticationStates: ['mobileverification', 'mobileverification_success', 'mobileverification_error'],

            showGreeting: false,
            
            uiState: '',            
            formValid: true,
            loading: false,
            snackbar: false,
            snackbarMessage: '',
            error: '', 

            // firstNameRules: [
            //     fieldNotEmpty,            
            //     (v) => fieldMinLength(v, 2)   
            // ],
            // lastNameRules: [
            //     fieldNotEmpty,            
            //     (v) => fieldMinLength(v, 2)   
            // ],
            mobileNoRules: [
                fieldNotEmpty,            
                (v) => /^\d{10}$/.test(v) || this.$root.$i18n.t("valid.mobilenotvalid"),
                (v) => fieldMinLength(v, 10)   
            ],
            nameRules: [
                (v) => !!v || this.$root.$i18n.t("valid.fieldisrequired"),               
            ]
        }
    },
    computed: {
        ...mapGetters(['loggedInUser'/*, 'error'*/])
    },
    watch: {
        loggedInUser(val) {
            if (val && val.token && val.token.length > 0) {
                // this.makeToast("success", "Successfully Logged In");
                console.log('logged in successfully ')
                this.loading = true
                setTimeout(() => {
                    this.$router.push('/')
                }, 200)
            }
        },
        // error(val) {            
        //     if (val != null) {
        //         this.error = val.message;                
        //     }
        // }
    },
    methods: {
        ...mapActions(['login']),
        triggerValidationMessages(vtField, errorMessagesArray) {
            let isValid = true;
            isValid = vtField.validate();
            errorMessagesArray.splice(0);
            errorMessagesArray.push(...vtField.errorBucket);
            return isValid;
            //console.log("thizizsomething");
        },
        signUp(event) {    
            //this.$refs.regForm.validate();

            // this.$refs.mobilefield.validate();
            // this.mobileErrorMessages.push("something");
            this.triggerValidationMessages(this.$refs.mobilefield, this.mobileErrorMessages);

            // this.error = '';
            // if(!this.formValid) {
            //     this.snackbarMessage = "There are invalid fields";
            //     this.snackbar = true;
            //     return;
            // }    
            //return;
        },
        triggerActivation() {    
            let self = this;            
            //self.loading = true;
            //api.auth.activateUser(self.activationCode)
            api.auth.validateActivationCode(self.activationCode)
            .then((rsp)=>{            
                if(rsp.data.status === 'alreadyactivated') {
                    self.uiState = "alreadyactivated";
                }
                else {
                    self.uiState = ''
                    if(rsp.data.firstName) {
                        this.firstName = rsp.data.firstName;
                        this.showGreeting = true;
                    }
                    if(rsp.data.lastName) {
                        this.lastName = rsp.data.lastName;
                    }
                }
                // self.header_message = "Activation Completed";
                // self.subheader_message = 'You can now login into your account!';
                //self.success = true;

            })
            .catch((error)=>{
                self.uiState = "invalidcode";
                // self.header_message = "Hmm... That's weird..."
                // self.subheader_message = error.response.data.message || "There has been an error while activating you account.";                
            })
            .finally(()=>{
                self.loading = false;
            })
        },
        async completeRegistration() {
            //check if mobile is verified else show warning/error message
            if(!this.mobileVerified) {
                this.error = 'mustverifymobile';
                return;
            }            
            self.loading=true;
            try {
                //send all data to back=end > back-end should put mobile verified number to actual field
                const rsp = await api.auth.completeRegistration({
                    code: this.activationCode,
                    smscode: this.mobileVerificationCode,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    mobile: this.mobileNo,
                    selectedLanguage: this.$root.$i18n.locale
                });
                if(rsp.data.status === 'success'){
                    if(rsp.data.registration_completion_token) {
                        this.login({
                            username: rsp.data.registration_completion_token, 
                            password: rsp.data.registration_completion_token
                        });
                    }
                }
                else {
                    this.error = 'error occured: ' + error;
                }                
            } catch (error) {
                this.error = 'error occured: ' + error;
            } finally {
                self.loading = false;
            }
            //redirect to homepage -> start "guide"
        },
        async verifyMobile() {
            let self = this;
            this.error = '';
            if(this.triggerValidationMessages(this.$refs.mobilefield, this.mobileErrorMessages)) {
                self.mobileVerificationInProgress = true;
                try {
                    let activcode = self.activationCode;
                    let mobNo = self.mobileNo;
                    let lng = self.$root.$i18n.locale;
                    let rslt = await api.auth.requestSmsVerification(activcode, mobNo, lng);
                    //await new Promise(r => setTimeout(r, 2000));            
                    self.uiState = 'mobileverification';                      
                } catch (error) {
                    console.log("mobile verification error: " + JSON.stringify(error));
                } finally {
                    await new Promise(r => setTimeout(r, 30000));     
                    self.mobileVerificationInProgress = false;                    
                }                        
            }            
        },
        async verifyShortcode() {
            let self = this;
            try {
                self.smscodeVerificationInProgress = true;
                let rslt = await api.auth.validateSmsCode(self.activationCode, self.mobileNo, self.mobileVerificationCode);
                if(rslt.data.status === 'success') {
                    this.uiState = 'mobileverification_success';
                    this.mobileVerified = true;
                }
                else {
                    this.uiState = 'mobileverification_error';
                    this.mobileVerified = false;
                }
            } catch (error) {
                this.uiState = 'mobileverification_error';
                this.mobileVerified = false;
            } finally {
                self.smscodeVerificationInProgress = false;
            }
        }
    },
    created() {
        this.activationCode = this.$route.params.code;
    },
    mounted() {
        this.triggerActivation();
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #f2f4f4 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
