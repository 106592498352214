<template>
    <v-row>
        <v-col :cols="filterColumnCount" v-if="filtersVisible">
            <base-card>
                <!-- <v-card-title>
                    <span class="font-weight-bold">Filters</span>
                </v-card-title> -->
                <v-card-text class="-pt-0">
                    <advanced-grid-filters-list :dynamic="false" :fields="filterfields" v-model:filtervalue="filtercriteria" @search="onSearch" />
                </v-card-text>                          
            </base-card>            
        </v-col>
        <v-col :cols="gridColumnCount">
            <base-card>
                <!-- <v-card-title class="pb-0">
                    Users
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    
                </v-card-title> -->
                <v-card-title>                                                        
                    <div class="d-flex justify-space-between flex">
                        <h5 class="ma-5 mb-0 d-flex flex">Users</h5>                        
                        <v-text-field
                                v-model="quicksearch"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details     
                                class="col-5"   
                                @input="triggerQuicksearch"
                            ></v-text-field>
                        <v-btn class="ma-2" dark color="primary" @click="toggleFilterView">
                                <v-icon>mdi-filter</v-icon>
                                Filters
                        </v-btn>
                        <!-- <v-btn class="ma-2" dark>
                            <v-icon>mdi-keyboard_arrow_down</v-icon>
                            Actions
                        </v-btn> -->
                        <v-btn class="ma-2" dark color="danger" to="/mng/system/users/invite">
                            <v-icon>mdi-plus</v-icon>
                            Add User
                        </v-btn>                        
                    </div>
                </v-card-title>
                <v-data-table
                    v-model="selected"                    
                    :headers="headers"
                    :options.sync="pagingOptions"
                    :server-items-length="totalItemCount"
                    :items="gridData"
                    item-key="name"
                    :show-select="false"
                    class="elevation-1 table-one"
                    multi-sort
                >
                    <template v-slot:item.fullname="{item}">
                        <div class="d-flex align-center">
                            <v-avatar class="mr-2" size="26" dark>
                                <img src="@/assets/images/avatars/001-man.svg" alt="" />
                            </v-avatar>
                            <p class="ma-0 font-weight-medium">
                                {{ item.fullname }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:item.aud_create_date="{item}">
                        <div class="d-flex align-center">                        
                            <p class="ma-0 font-weight-medium">
                                {{ item.aud_create_date | formatDate }}
                            </p>
                        </div>
                    </template>
                    

                    <template v-slot:item.action="{item}">
                        <div class="d-flex">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        :to="'/mng/system/users/edit/' + item.code"
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.status="{item}">
                        <template v-if="item.status === 'Active'">
                            <v-chip
                                class=""
                                color="success"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-check</v-icon>
                                {{ item.status }}
                            </v-chip>
                        </template>
                        <template v-else-if="item.status === 'Invited'">
                            <!-- <v-chip
                                class=""
                                color="warning"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-email</v-icon>
                                {{ item.status }}
                            </v-chip> -->
                            <v-chip
                                class=""
                                color="info"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-timer-sand</v-icon>
                                Awaiting Activation
                            </v-chip>
                        </template>
                        <template v-else-if="item.status === 'Awaiting Activation'">
                            <v-chip
                                class=""
                                color="info"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-timer-sand</v-icon>
                                {{ item.status }}
                            </v-chip>
                        </template>
                        <template v-else-if="item.status === 'Disabled'">
                            <v-chip
                                class=""
                                color="error"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-close</v-icon>
                                {{ item.status }}
                            </v-chip>
                        </template>
                    </template>
                </v-data-table>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import { api } from "src/api/index";
import moment from "moment";
import AdvancedGridFiltersList from "@/components/core/AdvancedGridFiltersList"

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Users List'
    },    
    components: {
        "advanced-grid-filters-list": AdvancedGridFiltersList        
    },
    data() {
        return {
            // gridConfig: {
            //     rowActions: [
            //         {
            //             title: "Edit",
            //             action: "localMethodNameGoesHere",
            //             icon: "iconGoesHere",
            //         },
            //         {
            //             title: "Delete",
            //             action: "localMethodNameGoesHere",
            //             icon: "iconGoesHere",
            //         },
            //     ],
            //     columns: [
            //         {title: "Name", 
            //         type: "text", 
            //         bindings: {
            //             datafield: "fullname",
            //             prependIcon: "",
            //             appendIcon: "",
            //             formatter: (rowData) => {
            //                 return v;
            //             },
            //             statusMapper: (rowData) => {
            //                 return {
            //                     color: "",
            //                     icon: "",
            //                     text: ""
            //                 }
            //             },

            //         }}
            //     ]
            // },
            totalItemCount: 0,
            quicksearch: '',
            pagingOptions: {},
            selected: [],
            baseData: [],
            select_field: null,
            select_operator: null,
            filtercriteria: null,
            filterfields: [
                {
                    value: "firstName",
                    text: "Firstname",
                    criteria: {},
                    type: "text"
                },
                {
                    value: "lastName",
                    text: "Lastname",
                    criteria: {},
                    type: "text"
                },
                {
                    value: "email",
                    text: "Email",
                    criteria: {},
                    type: "text"
                },
                {
                    value: "role",
                    text: "Role",
                    criteria: {},
                    type: "list",
                    listItems: [
                        {
                            value: "admin",
                            text: "Admin",
                        },
                        {
                            value: "viewer",
                            text: "Viewer",
                        },
                        {
                            value: "designer",
                            text: "Designer",
                        },
                        {
                            value: "dev",
                            text: "Developer",
                        },
                        {
                            value: "sysadmin",
                            text: "System Admin",
                        }
                    ]
                },
                {
                    value: "status",
                    text: "Status",
                    type: "list",
                    criteria: {},
                    listItems: [
                        {
                            value: "active",
                            text: "Active",
                        },
                        {
                            value: "invited",
                            text: "Invited",
                        },
                        {
                            value: "awaitingactivation",
                            text: "Awaiting Activation",
                        },
                        {
                            value: "disabled",
                            text: "Disabled",
                        },
                    ]
                },
                {
                    value: "brand",
                    text: "Brand",
                    type: "list",
                    criteria: {},
                    listItems: []
                    // [
                    //     {
                    //         selectvalue: "brand1",
                    //         selecttext: "Brand 1",
                    //     },
                    //     {
                    //         selectvalue: "brand2",
                    //         selecttext: "Brand 2",
                    //     },                        
                    // ]
                },
                {
                    value: "aud_create_date",
                    text: "Create Date",
                    criteria: {},
                    type: "date"
                },
                // {
                //     value: "some_numeric",
                //     text: "Some Numeric",
                //     criteria: {},
                //     type: "number"
                // },
            ],                    
            filtersVisible: false,
            headers: [
                {text: 'Name', align: 'start', value: 'fullname',  sortable: false},
                {text: 'Email', align: 'start', value: 'username'},                
                {text: 'Role', value: 'role'},
                // {text: 'Products', value: 'fat'},
                // {text: 'Wallet Balance', value: 'carbs'},
                {text: 'Status', value: 'status',  sortable: false},
                {text: 'Create Date', value: 'aud_create_date'},                
                {text: 'Action', value: 'action'}
            ]
        }
    },
    filters: {
        formatDate(value) {
            return moment.utc(value).format("DD/MM/YYYY");
        }
    },
    watch: {
      pagingOptions: {
        handler () {
          this.fetchGridData();
        },
        deep: true,
      },
    //   quicksearch: function() {
    //       this.fetchGridData();
    //   }
    },
    computed: {
        gridData() {
            const rspData = this.baseData;
            return this.formatListData(rspData);
        },
        filterColumnCount() {
            const d = this.filtersVisible;
            return (d ? 2 : 0);
        },
        gridColumnCount() {
            const d = this.filtersVisible;
            return (d ? 10 : 12);
        },
    },
    methods: {
        formatListData(rspData) {
            const d = rspData.map(d => { 
                return {
                    ...d, 
                    fullname: d.firstName + ' ' + d.lastName,
                    status: ( (d.disabled) ? "Disabled" :
                        (d.invitation_code) ? "Invited" :
                        (d.activation_date === "" || d.activation_date == null) ? "Awaiting Activation" : "Active"                         
                    )
                } })
            return d;
        },
        async fetchGridData() {            
            let qstr = "";
            //paging
            console.log("\nPAGING PAGING PAGING PAGING \n" + JSON.stringify(this.pagingOptions));
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagingOptions;
            const limit = itemsPerPage;
            const currentPage = page;
            qstr += "?currentPage=" + page + "&limit=" + limit;
            //sorting       
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            for(let i=0; i<sortBy.length; i++) {                
                qstr += "&sortBy[" + i + "]=" + sortBy[i];                
            }
            //sorting                   
            for(let i=0; i<sortDesc.length; i++) {                
                qstr += "&sortDesc[" + i + "]=" + sortDesc[i];                
            }     
            //criteria
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            if(this.filtercriteria && this.filtercriteria.qs) {                
                qstr += this.filtercriteria.qs;
            }      
            //quicksearch
            if(this.quicksearch && this.quicksearch.length > 2){
                //if(!qstr.includes("?")) qstr = "?" + qstr;
                qstr += "&quicksearch=" + encodeURIComponent(this.quicksearch);
            }      
            //alert(querystr);
            const rsp = await api.users.getList(qstr);
            this.baseData = rsp.data.data.map(d => { return {...d, fullname: d.firstName + ' ' + d.lastName} }); 
            this.totalItemCount = rsp.data.total;
        },
        toggleFilterView() {
            this.filtersVisible = ! this.filtersVisible
        },
        onSearch() {
            //alert(JSON.stringify(this.filtercriteria, null, 2));
            //alert(JSON.stringify(this.filtercriteria.qs, null, 2));
            this.fetchGridData();
        },
        triggerQuicksearch() {
            // if (this.quicksearch && this.quicksearch.length > 2) {
            //     this.fetchGridData();
            // }      
            this.fetchGridData();       
        }
    },
    async created() {
        //this.fetchGridData();
        // const rsp = await api.users.getList("");
        // this.baseData = rsp.data.data.map(d => { return {...d, fullname: d.firstName + ' ' + d.lastName} }); 
        // this.baseData = [{
        //             img: require('@/assets/images/avatars/001-man.svg'),
        //             name: 'Jhon Doe',
        //             calories: 'Walmart',
        //             fat: '95',
        //             carbs: '$14,384',
        //             protein: '03/24/2020',
        //             badge: 'Active'
        //         },
        //         {
        //             img: require('@/assets/images/avatars/002-woman.svg'),
        //             name: 'Elizabeth Doe',
        //             calories: 'KFC',
        //             fat: '876',
        //             carbs: '$38,384',
        //             protein: '03/24/2020',
        //             badge: 'Deactive'
        //         },]
        //console.log("DBGINDICATOR_ " + JSON.stringify(XYZ, null, ''));
    },
    async mounted() {
        let ff = this.filterfields.find(i=>i.value === "brand");        
        if(ff) {
            const rsp = await api.brand.getAll();            
            ff.listItems = rsp.data.map(i=> {return {value: i.code, text: i.title}});
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
