<template>
    <div>        
        <v-sheet class="px-6 pt-6">
            <div class="container">
                <div class="d-flex flex-wrap justify-space-between mb-8">
                    <h5 class="ma-3 mb-0 d-flex flex">{{ ( createMode ? "Create a New Adpoint" : "Edit Adpoint" ) }}</h5>                        
                    <div class="flex-1 text-right">
                        <v-btn class="ma-2" dark color="success" :loading="loading" @click="()=>{save()}">
                            <v-icon class="pr-2">mdi-content-save</v-icon>
                            Save
                        </v-btn>
                        <v-btn v-if="!createMode" class="ma-2" dark color="success" :loading="loading" @click="()=>{save(true)}">
                            <v-icon class="pr-2">mdi-content-save</v-icon>
                            Save & Close
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-sheet>
        <v-form  v-model="editFormValid" ref="editForm">
        <v-row justify="center">                 
            <v-col cols="10" md="5">
                <e-info-card
                    title="Primary Fields"
                    sub-title=""
                >
                    <template slot="basic-info-slot">     
                        <div>
                            <v-list>
                                <v-list-item>
                                    <!-- <v-list-item-avatar>
                                        <img :src="''" alt="" />
                                    </v-list-item-avatar> -->                                                                                                                              
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Adpoint ID" 
                                            v-model="adpointid"
                                            outlined
                                            :readonly="!createMode"   
                                            :validate-on-blur="createMode"
                                            :rules="(createMode ? codeRules : [])"                                       
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                               
                                    <v-list-item-content class="pt-0">
                                        <v-select                                                                     
                                            v-model="networkId"
                                            :items="networkOptions"                 
                                            label="Network"                                             
                                            :rules="requiredRules"
                                            :validate-on-blur="createMode"                                                 
                                            :clearable="true"      
                                            @input="onSchemeChanged"                
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>  
                                <v-list-item>                                                               
                                    <v-list-item-content class="pt-0">
                                        <v-select                                                                     
                                            v-model="deviceId"
                                            :items="deviceTypeOptions"                 
                                            label="Device Type"                                             
                                            :rules="requiredRules"
                                            :validate-on-blur="createMode"                                                 
                                            :clearable="true"  
                                            @input="onSchemeChanged"                    
                                        />
                                    </v-list-item-content>                                
                                </v-list-item> 
                                <v-list-item>                                                               
                                    <v-list-item-content class="pt-0">
                                        <v-select                                                                     
                                            v-model="brandId"
                                            :items="brandOptions"                 
                                            label="Brand"                                             
                                            :rules="requiredRules"
                                            :validate-on-blur="createMode"                                                 
                                            :clearable="true"                      
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                               
                                    <v-list-item-content class="pt-0">
                                        <v-select                                                                     
                                            v-model="location"
                                            :items="locationOptions"                 
                                            label="Location"                                                                                                                                                    
                                            :clearable="true"                      
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>  
                                <v-list-item>                                                               
                                    <v-list-item-content class="pt-0">
                                        <v-select                                                                     
                                            v-model="environment"
                                            :items="environmentOptions"                 
                                            label="Environment"                                                                                                                                                    
                                            :clearable="true"                      
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>     
                                <v-list-item v-if="fieldSchemeHasChanged"> 
                                    <v-list-item-content>
                                        <v-alert border="top" color="red lighten-2" dark>
                                            When either a new <strong>Network</strong> or <strong>Device Type</strong> is selected, you have to hit the 'Save' button before continuing, so that the correct Attribute Fields will be displayed, based on your selection.
                                        </v-alert>
                                    </v-list-item-content>                        
                                </v-list-item>
                            </v-list>  
                        </div>                      
                    </template>
                </e-info-card>
            </v-col>

            <v-col cols="10" md="5" v-if="allLocations.find(l=>l.code == location)">
                <e-info-card
                    title="Location & Operating Hours Information"
                    sub-title=""
                >
                    <template slot="basic-info-slot">     
                        <div>
                            <v-list>
                                <v-list-item>
                                    <pre>
                                        {{ JSON.stringify(allLocations.find(l=>l.code == location).extra_info, null, 6) }}
                                    </pre>                                    
                                </v-list-item>                                
                            </v-list>  
                        </div>                      
                    </template>
                </e-info-card>
            </v-col>

            <v-col cols="10" md="5">
                <e-info-card
                    title="Behaviour"
                    sub-title=""
                >
                    <template slot="basic-info-slot">     
                        <div>
                            <v-list>
                                <v-list-item>                                                               
                                    <v-list-item-content class="pt-0">
                                        <v-select                                                                     
                                            v-model="status"
                                            :items="['Online','Offline','Disabled']"                 
                                            label="Status"                                             
                                            :rules="requiredRules"
                                            :validate-on-blur="createMode"                                                 
                                            :clearable="true"                      
                                        />
                                    </v-list-item-content>                                
                                </v-list-item> 
                                <!-- <v-list-item>                                                             
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Default Daily Capacity Potential (Impressions)" 
                                            :rules="requiredRules"
                                            v-model.number="nominalCapacity"
                                            type="number"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>           -->
                                <v-list-item>                                                             
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Impression Factor" 
                                            :rules="requiredRules"
                                            v-model.number="impression_factor"
                                            type="number"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>                     
                            </v-list>  
                        </div>                      
                    </template>
                </e-info-card>
            </v-col>

            <v-col cols="10" md="5">
                <e-info-card
                    title="General Attributes"
                    sub-title=""
                >
                    <template slot="basic-info-slot">     
                        <div>
                            <v-list>     
                                <v-list-item v-for="attr in this.dynamicAttrs.generic" :key="attr.name">  
                                    <AttributeEditField v-bind="attr" v-model="attr.value" />                                          
                                </v-list-item>
                            </v-list>  
                        </div>                      
                    </template>
                </e-info-card>
            </v-col>

            <v-col cols="10" md="5">
                <e-info-card
                    title="Positional/Geographical"
                    sub-title=""
                >
                    <template slot="basic-info-slot">     
                        <div>
                            <v-list> 
                                <v-list-item v-for="attr in this.dynamicAttrs.positional" :key="attr.name">  
                                    <AttributeEditField v-bind="attr" v-model="attr.value" />                                          
                                </v-list-item>                                                    
                            </v-list>  
                        </div>                      
                    </template>
                </e-info-card>
            </v-col>

            <v-col cols="10" md="5">
                <e-info-card
                    title="Product Categorization"
                    sub-title=""
                >
                    <template slot="basic-info-slot">     
                        <div>
                            <v-list>  
                                <v-list-item v-for="attr in this.dynamicAttrs.productcategory" :key="attr.name">  
                                    <AttributeEditField v-bind="attr" v-model="attr.value" />                                          
                                </v-list-item>                                                   
                            </v-list>  
                        </div>                      
                    </template>
                </e-info-card>
            </v-col>


            
        </v-row>
        </v-form>
        <!-- <base-card class="transparent">
            
        </base-card> -->
        <v-snackbar
            v-model="snackbar"
            :timeout="4000"
            bottom
            tile
            :color="snackbarColor + ' -accent-2'"            
            >
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
</template>
<script>
import avatarGroupCard from '@/components/card/AvatarGroupCard';
import EInfoCard from '@/components/card/BasicInfoCard';
import { api } from "src/api/index";
import { fieldNotEmpty, fieldMinLength } from "src/core/vuetify-validators.js";
import AttributeEditField from '@/components/core/AttributeEditField';

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Network Edit'
    },
    components: {
        avatarGroupCard,
        EInfoCard,
        AttributeEditField
    },
    data() {
        return {
            //primary data
            adpointid: '',
            networkId: '',
            deviceId: '',
            brandId: '',
            location: '',
            locationAndOperInfo: null,
            environment: '',
            status: '',
            nominalCapacity: '',
            impression_factor: 1,
            dynamicAttrs: {},

            fieldSchemeHasChanged: false,


            type: '',
            crud_code: '',
            disabled: false,
            //ui mechanics      
            createMode: false,                  
            invitationFormValid: true,
            editFormValid: true,            
            //error
            loading: false,
            snackbar: false,
            snackbarColor: 'green',
            snackbarMessage: '',
            error: '',
            //reference data    
            networkOptions: [],
            deviceTypeOptions: [],
            brandOptions: [],
            environmentOptions: [{value: 'ADDREALITY', text: 'DisplayForce'},{value: 'PRODME', text: 'Product.me'},{value: 'OTHER', text: 'Other'}],
            locationOptions: [],
            allLocations: [],

            type_items: [
                        {
                            selectvalue: "physical",
                            selecttext: "Physical Devices",
                        },
                        {
                            selectvalue: "web",
                            selecttext: "Web Placement",
                        }                        
                    ],
            requiredRules: [
                fieldNotEmpty                
            ],
            codeRules: [
                fieldNotEmpty,            
                (v) => fieldMinLength(v, 2)  
            ],
            treeitems: [
        {
          id: 5,
          name: 'Country :',
          children: [
            {
              id: 6,
              name: 'Area :',
              children: [
                {
                  id: 7,
                  name: 'Store :',
                  children: [
                    { id: 8, name: 'Floor :' },
                    { id: 9, name: 'Section :' },
                  ],
                },
              ],
            }           
          ],
        },       
      ]
        }
    },
    computed: {
        status() {
            return (this.disabled) ? "Disabled" :
                    (this.invitation_code) ? "Invited" :
                    (this.activation_date === "" || this.activation_date == null) ? "Awaiting Activation" : "Active";                    
        }
    },
    async created() {
        if(this.$route.params.code) {
            this.crud_code = this.$route.params.code;       
            this.createMode = false;     
        }
        else {            
            this.createMode = true;
        }

        let rsp = {}
        if(!this.createMode) {
            rsp = await api.adpoint.getInitEditData(this.crud_code);
        }
        else {
            rsp = await api.adpoint.getInitEditData();
        }
        this.initEditForm(rsp);
    },
    async mounted() {      
        // if(!this.createMode) {
        //     const rspB = await api.network.getOne(this.crud_code);
        //     if(rspB && rspB.data) {
        //         this.code = rspB.data.code;
        //         this.title = rspB.data.title;
        //         this.description = rspB.data.description;
        //         this.type = rspB.data.type;            
        //     }
        //     //this.editFormValid = true;  
        // }      
    },
    methods: {
        initEditForm(rsp) {
            this.networkOptions = rsp.data.init.networkOptions;
            this.deviceTypeOptions = rsp.data.init.deviceTypeOptions;
            this.brandOptions = rsp.data.init.brandOptions;  
            this.locationOptions = rsp.data.init.locationOptions
            this.allLocations = rsp.data.init.allLocations;
            this.reloadFormData(rsp.data.edit);
        },
        reloadFormData(editData) {
            this.adpointid = editData.adpointid;
            this.networkId = editData.networkId;
            this.deviceId = editData.deviceId;
            this.brandId = editData.brandId;
            this.location = editData.location;
            this.status = editData.status || 'Online';
            this.environment = editData.environment;
            this.nominalCapacity = editData.nominalCapacity || 2880;
            this.impression_factor = editData.impression_factor || 1;        
            this.dynamicAttrs = this._convertAttrsToArray(editData.attributes);
            this.$refs.editForm.resetValidation();
            this.fieldSchemeHasChanged = false;
        },
        onSchemeChanged() {
            this.fieldSchemeHasChanged = true;
        },
        save(close=false) {
            console.log(JSON.stringify(this.dynamicAttrs.generic, null, 2));
            this.error = '';     
            this.$refs.editForm.validate();                          
            if(!this.editFormValid) {                
                this.snackbarColor = "red";
                this.snackbarMessage = "There are invalid fields.";
                this.snackbar = true;
                return;
            }
            this.loading = true;
            if(this.createMode) {
                api.adpoint.create(
                    {   
                        adpointid: this.adpointid,
                        networkId: this.networkId,
                        deviceId: this.deviceId,
                        brandId: this.brandId,
                        location: this.location,
                        nominalCapacity: this.nominalCapacity,
                        status: this.status,
                        environment: this.environment,
                        impression_factor: this.impression_factor,                        
                        attributes: this._convertArrayToAttrs(this.dynamicAttrs)
                    }                    
                )
                .then((rsp)=>{               
                    //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                    //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});
                    // this.snackbarColor = "green";
                    // this.snackbarMessage = "User updated successfully";
                    // this.snackbar = true;  
                    this.crud_code = rsp.data.id;       
                    this.createMode = false;
                    this.reloadFormData(rsp.data);
                    this.$router.replace("/mng/adpoints/management/edit/" + rsp.data.id);                    
                })
                .catch((error)=>{
                    //this.error = error.response.data.message;
                    this.snackbarColor = "red";
                    this.snackbarMessage = error.response.data.message;
                    this.snackbar = true;
                })
                .finally(()=>{
                    this.loading = false;
                })
            }
            else { //edit mode
                api.adpoint.update(this.crud_code, 
                    {   
                        adpointid: this.adpointid,
                        networkId: this.networkId,
                        deviceId: this.deviceId,
                        brandId: this.brandId,
                        location: this.location,
                        nominalCapacity: this.nominalCapacity,
                        status: this.status,
                        environment: this.environment,
                        impression_factor: this.impression_factor,                        
                        attributes: this._convertArrayToAttrs(this.dynamicAttrs)
                    }
                )
                .then(async (rsp)=>{               
                    //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                    //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});                                                 
                    if(close) {
                        this.$router.back();
                    }
                    else {
                        this.snackbarColor = "green";
                        this.snackbarMessage = "Adpoint saved successfully";
                        this.snackbar = true;             
                        this.reloadFormData(rsp.data);
                    }

                    //this.$router.push("/mng/adpoints/management/edit/" + rsp.data.id);
                    // this.$router.push("/mng/adpoints/management/edit");
                })
                .catch((error)=>{
                    //this.error = error.response.data.message;
                    this.snackbarColor = "red";
                    this.snackbarMessage = error.response.data.message;
                    this.snackbar = true;
                })
                .finally(()=>{
                    this.loading = false;
                })
            }                
        },          
        _convertAttrsToArray(src) {
            const rslt = Object.values(src).reduce((rslt, arrayItem) => {
                if(!arrayItem.hierarchy) {
                    if(!rslt['generic']) rslt['generic'] = [];
                    rslt['generic'].push(arrayItem);
                }
                else {
                    if(!rslt[arrayItem.hierarchy]) rslt[arrayItem.hierarchy] = [];
                    rslt[arrayItem.hierarchy].push(arrayItem);
                }
                return rslt;
            }, {});
            let orderedRslt = {};
            for(const group in rslt){
                orderedRslt[group] = rslt[group].sort((a,b)=>{
                    if(a.name < b.name) return -1;
                    if(a.name > b.name) return 1;
                    return 0;
                })
            }
            return orderedRslt;
        },
        _convertArrayToAttrs(src) {
            let rslt = {};
            for(const srcProp in src) {
                for(const attr of src[srcProp]) {
                    rslt[attr.name] = attr;
                }
            }            
            return rslt;
        },    
    }
}
</script>
<style lang="scss">
.ul-widget-profile-img {
    position: relative;
}
.ul-widget-profile-img:after {
    // content: "";
    // position: absolute;
    // top: 44px;
    // left: 50%;
    // width: 1px;
    // height: calc(100% - 30px);
    // background: #B3C0CE;
    content: '';
    position: absolute;
    top: 44px;
    left: 50%;
    width: 1px;
    height: calc(100% - 30px);
    background: #b3c0ce;
}
</style>
