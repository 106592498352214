<template>
    <v-list outlined style="border-radius: 20px; " class="mb-4">
        <v-list-item>
            <v-list-item-content class="pb-0">
                
                <h6 class="mb-0 ml-4"> {{ $t("dateavail_comp.custom date") }}                                  
                    <v-btn class="text-capitalize"                                                                                     
                        color="primary"                                 
                        dark             
                        small   
                        style="margin-left: 20px;"
                        @click="addNew"
                                                                                                            
                    >                                                    
                        + Add Interval
                    </v-btn>
                </h6>
            </v-list-item-content>                                                                                      
        </v-list-item>
        <v-list-item :key="weekday+index" v-for="(intrv, index) in value">                                           
            <v-list-item-content class="pb-0">
                <div style="max-width: 150px; display: inline-block">
                        <DatepickerPopup 
                            v-model="intrv.availdate"
                            label="Specific Date"                                                
                        ></DatepickerPopup>
                    </div> 
                <v-select         
                    placeholder=""                                                            
                    v-model="intrv.intervalstart_hour"
                    :items="hourOptions"                 
                    label="Απο"                     
                    :rules="[]"     
                    required                                                                                                                       
                    style="max-width: 60px;display: inline-block; margin-left: 10px;"
                />
                <span style="max-width: 20px; display: inline-block; margin-left: 10px;"> : </span>
                <v-select         
                    placeholder=""                                                            
                    v-model="intrv.intervalstart_minute"
                    :items="minuteOptions"                 
                    label=" "                     
                    :rules="[]"     
                    required                                                                                                             
                    style="max-width: 60px;display: inline-block;"
                />
                <span style="max-width: 20px; display: inline-block; margin-left: 10px;"> - </span>
                <v-select         
                    placeholder=""                                                            
                    v-model="intrv.intervalend_hour"
                    :items="hourOptions"                 
                    label="Εώς"                     
                    :rules="[]"     
                    required                                                                                                                       
                    style="max-width: 60px;display: inline-block;"
                />
                <span style="max-width: 20px; display: inline-block; margin-left: 10px;"> : </span>
                <v-select         
                    placeholder=""                                                            
                    v-model="intrv.intervalend_minute"
                    :items="minuteOptions"                 
                    label=" "                     
                    :rules="[]"     
                    required                                                                                                             
                    style="max-width: 60px;display: inline-block;"
                />
                <v-btn class="text-capitalize"                                                                                     
                        color="error"                                 
                        dark             
                        small   
                        style="margin-left: 10px; max-width: 50px;display: inline-block; "
                        @click="remove(index)"                                                                  
                    >                                                    
                        X
                    </v-btn>
            </v-list-item-content>                                            
        </v-list-item>
    </v-list>
  </template>
  
  <script>
  import DatepickerPopup from "@/components/core/DatepickerPopup";

  export default {
      model: {
          prop: "value",
          event: "change"
      },
      components: {
        DatepickerPopup
      },  
      data() {
          return {
              hourOptions: ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
              minuteOptions: ["00","05","10","15","20","25","30","35","40","45","50","55"],
          }
      },
      props: {
          weekday: {
            type: String,
            default: () => {""}
          },
          value: {
              type: Array,
              default: () => ([]),
          },
      },
  
      methods: {
          emit() {
              this.$emit('change', this.value);
          },
          remove(idx) {
            this.value.splice(idx, 1);
          },
          addNew() {
            //create new weekday availability interval
            const currentDate = new Date();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const day = currentDate.getDate().toString().padStart(2, '0');
            const year = currentDate.getFullYear();

            let newInterval = {     
                availdate: year.toString() + "-" + month + "-" + day,
                intervalstart_hour: "09",
                intervalstart_minute: "00",
                intervalend_hour: "21",
                intervalend_minute: "00",
            };
            this.value.push(newInterval);
          }
      },
  }
  </script>
  
  <style>
  
  </style>