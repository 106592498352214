<template>
    <div class="page-wrap-">
        <v-row style="min-height: 100vh">
            <v-col cols="0" md="2" class="ma-0 pa-0 text-center " align-self="center">
            </v-col>
            <v-col cols="12" md="4" class="ma-0 pa-5 text-center " align-self="center">
                <h3 style="margin-bottom: -20px">{{ $t("Signup.sign up") }}</h3>                
                <img src="@/assets/images/massage-logo-w.png" alt="" class="mb-0 pb-0" /> 
                <!-- <img src="@/assets/images/flags/1x1/gr.svg" alt="" class="mb-0 pb-0" />  -->
            </v-col>
            <v-col cols="12" md="4" class="ma-0 pa-0" align-self="center">
                <div v-if=" uiState == '' || uiState == null " class="session-form-hold align-self-stretch">
                    <base-card>
                        <v-progress-linear
                            :active="loading"
                            :indeterminate="loading"
                            absolute
                            top
                            color="primary"
                        ></v-progress-linear>
                        <v-card-text class="text-center pb-10">
                            <div class="d-flex justify-space-between">
                                <h6 class="text--disabled- font-weight-medium mb-2">
                                    {{ $t("Signup.create dedicated account") }}
                                </h6>
                                <LanguageSelector></LanguageSelector>
                            </div>                            
                            <!-- <v-form class="pt-10" v-if="!submitted" v-model="formValid" ref="regForm"></v-form> -->
                            <v-form class="pt-10" v-if="!submitted" v-model="formValid" ref="regForm">
                                <v-text-field 
                                    label="Email" 
                                    v-model="email"
                                    :error-messages="usernameValstate"
                                    :rules="emailRules"      
                                    :validate-on-blur="true"
                                    :disabled="!emailEditable"
                                />
                                <v-text-field
                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show ? 'text' : 'password'"
                                    name="input-10-2"
                                    label="Password" 
                                    :rules="passwordRules"
                                    :error-messages="passwordValstate" 
                                    ref="pwdField"
                                    v-model="password"
                                    @click:append="show = !show"        
                                    :validate-on-blur="true"                    
                                ></v-text-field>
                                <!-- <v-text-field
                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show ? 'text' : 'password'"
                                    name="input-10-2"
                                    v-model="retypePassword"
                                    label="Confirm Password"
                                    :rules="retypePasswordRules"                            
                                    @click:append="show = !show"   
                                    :validate-on-blur="true"                         
                                ></v-text-field>    
                                <v-row>
                                    <v-text-field 
                                        label="Firstname" 
                                        v-model="firstName"    
                                        :rules="firstNameRules"
                                        :validate-on-blur="true"
                                        class="col-6"
                                    />
                                    <v-text-field 
                                        label="Lastname" 
                                        v-model="lastName"  
                                        :rules="lastNameRules"  
                                        :validate-on-blur="true"                          
                                        class="col-6"
                                    />                             
                                </v-row>                                        -->
                                <!-- <v-text-field 
                                    label="Invitation Code" 
                                    v-model="invitationCode"   
                                    :rules="invitationCodeRules"  
                                    :validate-on-blur="true"                          
                                    
                                />  -->
                                <v-btn class="mb-4 mt-8 text-capitalize"                                     
                                    block 
                                    color="#EB8E45" 
                                    @click="signUp" 
                                    dark
                                    :loading="loading"                                    
                                >
                                    <v-icon left>mdi-arrow-right</v-icon>
                                    {{ $t("Signup.Continue") }}
                                </v-btn>
                                <v-alert dense outlined type="error" v-if="error">
                                    {{ error }}
                                </v-alert>
                                <div class="d-flex justify-space-between mb-2 mt-10">
                                    <h6 class="text--disabled- font-weight-medium">
                                        {{ $t("Signup.or log in with") }}
                                    </h6>
                                    <span>&nbsp;</span>
                                </div>       

                                <v-btn
                                    block 
                                    class="mb-2 text-capitalize"                                                                                
                                    color="#EA4335"
                                    dark
                                    @click="googleLogin"
                                >
                                    <v-icon left>mdi-google</v-icon>
                                    Google</v-btn
                                > 
                                <v-btn
                                    block 
                                    class="mb-2 text-capitalize"                                                                                
                                    color="#1877F2"
                                    dark
                                    @click="facebookLogin"
                                >
                                    <v-icon left>mdi-facebook</v-icon>
                                    Facebook</v-btn
                                > 
                                <v-btn
                                    block 
                                    class="mb-2 text-capitalize"                                                                                
                                    color="black"
                                    dark
                                >
                                    <v-icon left>mdi-apple</v-icon>
                                    Apple</v-btn
                                >                          

                                <!-- <div class="d-flex justify-around- flex-wrap- justify-space-between-">
                                    <v-btn
                                        class="mb-4 text-capitalize d-flex mr-2"                                                                                
                                        color="#EA4335"
                                        dark
                                        style="flex: 1"
                                        @click="googleLogin"
                                    >
                                        <v-icon left>mdi-google</v-icon>
                                        Google</v-btn
                                    > 
                                    <v-btn
                                        class="mb-4 text-capitalize d-flex ml-2"                                                                                
                                        color="#1877F2"
                                        dark
                                        style="flex: 1"
                                        @click="facebookLogin"
                                    >
                                        <v-icon left>mdi-facebook</v-icon>
                                        Facebook</v-btn
                                    >                                     
                                </div>
                                <div class="d-flex justify-around- flex-wrap- justify-space-between-">
                                    <v-btn
                                        class="mb-4 text-capitalize d-flex mr-2"                                                                                
                                        color="black"
                                        dark
                                        style="flex: 1"
                                    >
                                        <v-icon left>mdi-apple</v-icon>
                                        Apple</v-btn
                                    > 
                                    <v-btn
                                        class="mb-4 text-capitalize d-flex ml-2"                                                                                
                                        color="#1877F2"
                                        dark
                                        style="flex: 1"
                                    >
                                        <v-icon left>mdi-instagram</v-icon>
                                        Instagram</v-btn
                                    >                                     
                                </div> -->
                                
                                <div class="d-flex  justify-space-between- text-center">
                                    <v-btn
                                        class="text-capitalize"
                                        block
                                        text
                                        small
                                        color="black"
                                        to="/auth/login"
                                        >{{ $t("Signup.Sign In To Existing Account") }}</v-btn
                                    >
                                </div>
                                <!-- <div class="d-flex justify-around flex-wrap">
                                    <v-btn text small color="primary" to="/auth/login"
                                        >Sign in to existing account</v-btn
                                    >
                                </div> -->
                            </v-form>
                            <div v-else class="pt-10">                                
                                <h5>{{ $t("Signup.Activation Email Sent") }}</h5>
                                <h6 class="font-weight-medium mb-6" style="font-size: 2.2em">
                                    📫
                                </h6>
                                <h6 class="text--disabled font-weight-medium mb-10">
                                    {{ $t("Signup.you will receive activation check inbox") }}
                                    <!-- You will receive an activation e-mail shortly, <br/>
                                    so check your inbox to complete the signup process and create your account before logging in. -->
                                </h6>
                                <div class="d-flex  justify-space-between- text-center">
                                    <v-btn
                                        class="text-capitalize"
                                        block
                                        text
                                        small
                                        color="black"
                                        to="/auth/login"
                                        >{{ $t("Signup.Go to Login Screen") }}</v-btn
                                    >
                                </div>
                                <!-- <v-btn
                                        text
                                        small
                                        color="primary"
                                        to="/auth/login"
                                        >Go to Login Screen</v-btn
                                    > -->

                                <!-- <v-btn class="mb-4" block color="primary" to="/auth/signup" dark
                                    >Go to Sign-In</v-btn> -->
                            </div> 
                        </v-card-text>
                    </base-card>                    
                </div>
                <div v-else-if=" uiState == 'useralreadyactivated' " class="session-form-hold align-self-stretch">
                    <base-card>
                        <v-card-text class="text-center pb-10">
                            <h6 class="text--disabled- font-weight-medium mb-2">
                                {{ $t("Signup.user already activated") }}
                            </h6>
                        </v-card-text>
                    </base-card>
                </div>
                <div v-else class="session-form-hold align-self-stretch">
                    <base-card>
                        <v-card-text class="text-center pb-10">
                            <h6 class="text--disabled- font-weight-medium mb-2">
                                {{ $t("Signup.Invalid Invitation Link") }}
                            </h6>
                        </v-card-text>
                    </base-card>
                </div>
            </v-col>
            <v-col cols="0" md="2" class="ma-0 pa-0 text-center " align-self="center">
            </v-col>
        </v-row>        
        <v-snackbar
            v-model="snackbar"
            :timeout="4000"
            top
            tile
            color="red accent-2"
            >
            {{ snackbarMessage }}
    </v-snackbar>
    </div>
    
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import {api} from "src/api/index";
import { fieldNotEmpty, fieldMinLength } from "src/core/vuetify-validators.js"
import LanguageSelector from "@/components/core/LanguageSelector";

export default {
    name: 'SignUp',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Sign Up'
    },
    components: {
        LanguageSelector
    },
    data() {
        return {
            submitted: false,

            signupResponse: null,

            show: false,
            email: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',                        
            retypePassword: '',
            invitationCode: '',

            uiState: '',
            emailEditable: false,
            formValid: true,
            loading: false,
            snackbar: false,
            snackbarMessage: '',
            error: '',

            emailRules: [
                // fieldNotEmpty,
                // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                // (v) => fieldMinLength(v, 5)
                (v) => !!v || this.$root.$i18n.t("valid.fieldisrequired"),
            ],
            passwordRules: [
                // fieldNotEmpty,             
                // (v) => fieldMinLength(v, 8) 
                (v) => !!v || this.$root.$i18n.t("valid.fieldisrequired"),               
            ],
            retypePasswordRules: [
                this.matchingPasswords
            ],
            firstNameRules: [
                fieldNotEmpty,            
                (v) => fieldMinLength(v, 2)   
            ],
            lastNameRules: [
                fieldNotEmpty,            
                (v) => fieldMinLength(v, 2)   
            ],
            invitationCodeRules: [
                fieldNotEmpty,            
            ]
        }
    },
    computed: {
        ...mapGetters([/*'loggedInUser', 'error',*/ 'selectedLanguage']),
        usernameValstate() {            
            // //let err = this.error;
            // let err = this.$state.getters.error;
            // if(err.username) {
            //     if(err.username.msg) {
            //         return err.username.msg;
            //     }
            // }
            // return "ttttt";
            // //return Date.now().toString();
            return this.signupResponse?.valstate?.email?.msg;
        },
        passwordValstate() {
            return this.signupResponse?.valstate?.password?.msg;
        },
        generalValstate() {
            return this.signupResponse?.valstate?.general?.msg;
        },
        generalValstateValid() {
            let r = this.signupResponse?.valstate?.general?.valid;
            if(typeof r === 'undefined') {
                return true;
            }
            else {
                return r;
            }            
            // console.log("generalValstateValid: " + r);
            // return this.error?.general?.valid;
        }
    },
    methods: {
        signUp(event) {      
            // this.$refs.regForm.validate();
            // this.error = '';
            // if(!this.formValid) {
            //     this.snackbarMessage = "There are invalid fields";
            //     this.snackbar = true;
            //     return;
            // }                                
            this.loading = true;          
            api.auth.signUp({
                username: this.email,
                email: this.email,
                password: this.password,
                // retypedPassword: this.retypePassword,
                // firstName: this.firstName,
                // lastName: this.lastName,
                invitationCode: this.invitationCode,
                selectedLanguage: this.selectedLanguage,
            })
            .then((rsp)=>{        
                this.signupResponse = rsp.data;        
                this.submitted = true;
            })
            .catch((error)=>{
                //this.error = error.response.data.message;
                this.signupResponse = error.response.data;
                // this.snackbarMessage = 
                // this.snackbar = true;
            })
            .finally(()=>{
                this.loading = false;
            })            
        },
        matchingPasswords: function() {            
            if (this.password === this.retypePassword) {
                return true;
            } else {
                return 'Passwords do not match.';
            }
        },
        initializeInvCode: async function() {
            this.invitationCode = this.$route.query.inv;
            if(this.$route.query.inv){
                this.emailEditable = false;
                await this.validateInvCode(this.$route.query.inv)
            }
            else {
                this.emailEditable = true;
            }
        },
        validateInvCode: async function(invCode) {
            try {
                let rsp = await api.auth.validateInvitationCode(invCode);
                alert(rsp.data.regstatus);
                if(rsp.data.regstatus === 'invited') {
                    //do nothing - you are right where you belong
                    this.email = rsp.data.email;
                }
                else if (rsp.data.regstatus === 'pendingactivation') {
                    if(rsp.data.activation) {
                        this.$router.push("/auth/activation/" + rsp.data.activation);
                    }
                    else {
                        this.uiState = 'invalidinvitation';
                    }
                } 
                else if (rsp.data.regstatus === 'activated') {
                    this.uiState = 'useralreadyactivated';
                }
                else {
                    this.uiState = 'invalidinvitation';
                }   
            } catch (error) {                
                this.uiState = 'invalidinvitation';
            }            
        },
        googleLogin() {
            //this.$router.push('/api/auth/google');
            window.location.href = "/api/auth/google";
        },
        facebookLogin() {
            //this.$router.push('/api/auth/google');
            window.location.href = "/api/auth/facebook";
        },
    },
    mounted() {        
        this.initializeInvCode(this.$route.query.inv);
        //this.invitationCode = this.$route.query.inv;
    }
    // created() {
    //     alert(this.$route.query.inv);
    //     //this.invitationCode = this.$route.query.inv;
    // }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #f2f4f4 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
