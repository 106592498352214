<template>
    <div>        
        <v-sheet class="px-6 pt-6">
            <div class="container">
                <div class="d-flex flex-wrap justify-space-between mb-8">
                    <h5 class="ma-3 mb-0 d-flex flex">{{ ( createMode ? "Create a New Network" : "Edit Network" ) }}</h5>    
                    <div class="flex-1 text-right">                        
                        <v-btn class="ma-2" dark color="success" :loading="loading" @click="save">
                            <v-icon class="pr-2">mdi-content-save</v-icon>
                            Save
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-sheet>
        <v-row justify="center">     
            <!-- <v-form  v-model="editFormValid" ref="editForm">        -->
            <v-col cols="10" md="5">
                <e-info-card
                    title=" "
                    sub-title=""
                >
                    <template slot="basic-info-slot">     
                        <v-form  v-model="editFormValid" ref="editForm">
                            <v-list>
                                <v-list-item>
                                    <!-- <v-list-item-avatar>
                                        <img :src="''" alt="" />
                                    </v-list-item-avatar> -->                                                                                                                              
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Code" 
                                            v-model="code"
                                            outlined
                                            :readonly="!createMode"   
                                            :validate-on-blur="createMode"
                                            :rules="(createMode ? codeRules : [])"                                       
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                              
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Title" 
                                            v-model="title"
                                            :rules="firstNameRules"
                                            :validate-on-blur="createMode"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                             
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Description" 
                                            v-model="description"
                                            :rules="firstNameRules"
                                            :validate-on-blur="createMode"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                               
                                    <v-list-item-content class="pt-0">
                                        <v-select                                                                     
                                            v-model="type"
                                            :items="type_items"                 
                                            label="Target Type" 
                                            item-text="selecttext"
                                            item-value="selectvalue"    
                                            :rules="firstNameRules"
                                            :validate-on-blur="createMode"                                                 
                                            :clearable="true"                      
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item v-if="!createMode && ['kots_stores'].includes(code)"> 
                                    <v-list-item-content class="pt-0">
                                        <v-btn class="ma-2" dark color="info" :loading="loadingSync" @click="runSync">
                                            <v-icon class="pr-2">mdi-autorenew</v-icon>
                                            Run Live Sync: Yodiwo Signage
                                        </v-btn>
                                    </v-list-item-content>      
                                </v-list-item>  
                                <v-alert v-if="syncInfo"
                                    border="top"
                                    colored-border
                                    type="info"
                                    elevation="2"
                                >
                                    <ul>
                                        <li>Downloaded: {{syncInfo.downloaded}}</li>
                                        <li>Updated: {{syncInfo.updated}}</li>
                                        <li>Added: {{syncInfo.added}}</li>
                                    </ul>                                    
                                </v-alert>    
                                <v-alert
                                    v-if="syncInfo && syncInfo.errors.length>0"
                                    border="right"
                                    colored-border
                                    type="error"
                                    elevation="2"
                                >
                                    <ul>                                        
                                        <li v-for="(er, idx) in syncInfo.errors"  :key="idx" >{{er}}</li>                                        
                                    </ul>
                                </v-alert>                            
                            </v-list>  
                        </v-form>                      
                    </template>
                </e-info-card>
            </v-col>


            <v-col cols="10" md="5" v-if="false">
                <base-card class="h-full">
                    <v-card-title>Network Attributes</v-card-title>
                    <v-card-text>
                    <v-treeview
                        rounded
                        hoverable
                        activatable
                        :items="treeitems"
                    ></v-treeview>
                    </v-card-text>
                </base-card>
            </v-col>
            <!-- </v-form> -->            
        </v-row>
        <!-- <base-card class="transparent">
            
        </base-card> -->
        <v-snackbar
            v-model="snackbar"
            :timeout="4000"
            top
            tile
            :color="snackbarColor + ' -accent-2'"            
            >
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
</template>
<script>
import avatarGroupCard from '@/components/card/AvatarGroupCard';
import EInfoCard from '@/components/card/BasicInfoCard';
import { api } from "src/api/index";
import { fieldNotEmpty, fieldMinLength } from "src/core/vuetify-validators.js"

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Network Edit'
    },
    components: {
        avatarGroupCard,
        EInfoCard
    },
    data() {
        return {
            //primary data
            code: '',
            title: '',
            description: '',
            type: '',
            crud_code: '',
            disabled: false,
            //ui mechanics      
            createMode: false,                  
            invitationFormValid: true,
            editFormValid: true,            
            syncInfo: null,
            //error
            loading: false,
            loadingSync: false,
            snackbar: false,
            snackbarColor: 'green',
            snackbarMessage: '',
            error: '',
            //reference data                                             
            type_items: [
                        {
                            selectvalue: "physical",
                            selecttext: "Physical Devices",
                        },
                        {
                            selectvalue: "web",
                            selecttext: "Web Placement",
                        }                        
                    ],
            firstNameRules: [
                fieldNotEmpty,            
                (v) => fieldMinLength(v, 2)   
            ],
            codeRules: [
                fieldNotEmpty,            
                (v) => fieldMinLength(v, 2)  
            ],
            treeitems: [
        {
          id: 5,
          name: 'Country :',
          children: [
            {
              id: 6,
              name: 'Area :',
              children: [
                {
                  id: 7,
                  name: 'Store :',
                  children: [
                    { id: 8, name: 'Floor :' },
                    { id: 9, name: 'Section :' },
                  ],
                },
              ],
            }           
          ],
        },       
      ]
        }
    },
    computed: {
        status() {
            return (this.disabled) ? "Disabled" :
                    (this.invitation_code) ? "Invited" :
                    (this.activation_date === "" || this.activation_date == null) ? "Awaiting Activation" : "Active";                    
        }
    },
    created() {
        if(this.$route.params.code) {
            this.crud_code = this.$route.params.code;       
            this.createMode = false;     
        }
        else {            
            this.createMode = true;
        }
        
    },
    async mounted() {      
        if(!this.createMode) {
            const rspB = await api.network.getOne(this.crud_code);
            if(rspB && rspB.data) {
                this.code = rspB.data.code;
                this.title = rspB.data.title;
                this.description = rspB.data.description;
                this.type = rspB.data.type;            
            }
            //this.editFormValid = true;
        }          
    },
    methods: {
        runSync() {
            this.loadingSync = true;            
            api
            .network
            .runSync(this.code)
            .then((rsp)=>{
                this.syncInfo = rsp.data;
                //alert(JSON.stringify(rsp.data));
            })
            .catch((error)=>{
                //this.error = error.response.data.message;
                // this.snackbarColor = "red";
                // this.snackbarMessage = error.response.data.message;
                // this.snackbar = true;
            })
            .finally(()=>{
                this.loadingSync = false;
            })
        },
        save() {
            this.error = '';     
            this.$refs.editForm.validate();                          
            if(!this.editFormValid) {                
                this.snackbarColor = "red";
                this.snackbarMessage = "There are invalid fields.";
                this.snackbar = true;
                return;
            }                                
            this.loading = true;   
            if(this.createMode) {
                api.network.create({      
                    code: this.code,                          
                    title: this.title,
                    description: this.description,
                    type: this.type,              
                })
                .then((rsp)=>{               
                    //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                    //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});
                    // this.snackbarColor = "green";
                    // this.snackbarMessage = "User updated successfully";
                    // this.snackbar = true;  
                    this.$router.push("/mng/adpoints/networks/");
                })
                .catch((error)=>{
                    //this.error = error.response.data.message;
                    this.snackbarColor = "red";
                    this.snackbarMessage = error.response.data.message;
                    this.snackbar = true;
                })
                .finally(()=>{
                    this.loading = false;
                })
            }  
            else {
                api.network.update(this.crud_code, {                                
                    title: this.title,
                    description: this.description,
                    type: this.type,               
                })
                .then((rsp)=>{               
                    //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                    //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});
                    // this.snackbarColor = "green";
                    // this.snackbarMessage = "User updated successfully";
                    // this.snackbar = true;  
                    this.$router.push("/mng/adpoints/networks/");
                })
                .catch((error)=>{
                    //this.error = error.response.data.message;
                    this.snackbarColor = "red";
                    this.snackbarMessage = error.response.data.message;
                    this.snackbar = true;
                })
                .finally(()=>{
                    this.loading = false;
                })
            }                 
        },       
    }
}
</script>
<style lang="scss">
.ul-widget-profile-img {
    position: relative;
}
.ul-widget-profile-img:after {
    // content: "";
    // position: absolute;
    // top: 44px;
    // left: 50%;
    // width: 1px;
    // height: calc(100% - 30px);
    // background: #B3C0CE;
    content: '';
    position: absolute;
    top: 44px;
    left: 50%;
    width: 1px;
    height: calc(100% - 30px);
    background: #b3c0ce;
}
</style>
