<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn          
          dark
          v-bind="attrs"
          v-on="on"
          class="d-flex" style="width: 100%; margin-bottom: 5px;" small color="secondary" outlined
          @click="initData"
        >
          Load Tmpl.
        </v-btn>        
      </template>
      <v-card>
        <v-card-title>Select Adpoint Filtering Template <v-text-field                
                v-model="quicksearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details     
                class="mt-0 pt-0 ml-5"                                                                          
        ></v-text-field></v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px;">            
          <v-radio-group
            v-model="dialogm1"
            column
          >
            <v-radio v-for="choice in filteredchoices" :key="choice"
              :label="choice"
              :value="choice"
            ></v-radio>            
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="selectTemplate"
          >
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { api } from "src/api/index";
export default {
    name: 'FilterTemplatePickerDialog',
    props: {
        
    },    
    data () {
      return {
        dialogm1: '',
        dialog: false,
        choices: [],
        quicksearch: '',
      }
    },  
    computed: {
      filteredchoices() {
        return this.choices.filter(c=>c.toLowerCase().includes(this.quicksearch.toLowerCase()));
      }
    },
    methods: {
        async initData() {
            this.choices.length = 0;
            const rsp = await api.adpoint.getTemplates();
            this.choices=rsp.data;
        },
        async selectTemplate() {
            const rsp = await api.adpoint.getTemplateDetails(this.dialogm1);
            this.$emit('selected', rsp.data.filter ); 
            this.dialog=false;
        }
    }
}
</script>

<style>

</style>