<template>
    <div>        
        <v-sheet class="px-6 pt-6">
            <div class="container">
                <div class="d-flex flex-wrap justify-space-between mb-8">
                    <div class="flex-1 text-right">
                        <v-btn class="ma-2" dark color="success" :loading="loading" @click="save">
                            <v-icon class="pr-2">mdi-content-save</v-icon>
                            Save
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-sheet>
        <v-row justify="center">     
            <!-- <v-form  v-model="editFormValid" ref="editForm">        -->
            <v-col cols="10" md="5">
                <e-info-card
                    title="Primary Fields"
                    sub-title=""
                >
                    <template slot="basic-info-slot">     
                        <v-form  v-model="editFormValid" ref="editForm">
                            <v-list>
                                <v-list-item>
                                    <!-- <v-list-item-avatar>
                                        <img :src="''" alt="" />
                                    </v-list-item-avatar> -->                                                                                                                              
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Code" 
                                            v-model="code"
                                            outlined
                                            :readonly="!createMode"   
                                            :validate-on-blur="createMode"
                                            :rules="(createMode ? codeRules : [])"                                       
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                              
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Title" 
                                            v-model="title"
                                            :rules="firstNameRules"
                                            :validate-on-blur="createMode"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                             
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Description" 
                                            v-model="description"
                                            :rules="firstNameRules"
                                            :validate-on-blur="createMode"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                               
                                    <v-list-item-content class="pt-0">
                                        <v-select                                                                     
                                            v-model="orientation"
                                            :items="orientation_items"                 
                                            label="Default Orientation" 
                                            item-text="selecttext"
                                            item-value="selectvalue"                                                     
                                            :clearable="true"                      
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                            </v-list>  
                        </v-form>                      
                    </template>
                </e-info-card>
            </v-col>
            <!-- </v-form> -->            
        </v-row>
        <!-- <base-card class="transparent">
            
        </base-card> -->
        <v-snackbar
            v-model="snackbar"
            :timeout="4000"
            top
            tile
            :color="snackbarColor + ' -accent-2'"            
            >
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
</template>
<script>
import avatarGroupCard from '@/components/card/AvatarGroupCard';
import EInfoCard from '@/components/card/BasicInfoCard';
import { api } from "src/api/index";
import { fieldNotEmpty, fieldMinLength } from "src/core/vuetify-validators.js"

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Device Type Edit'
    },
    components: {
        avatarGroupCard,
        EInfoCard
    },
    data() {
        return {
            //primary data
            code: '',
            title: '',
            description: '',
            orientation: '',
            crud_code: '',
            disabled: false,
            //ui mechanics      
            createMode: false,                  
            invitationFormValid: true,
            editFormValid: true,            
            //error
            loading: false,
            snackbar: false,
            snackbarColor: 'green',
            snackbarMessage: '',
            error: '',
            //reference data                                             
            orientation_items: [
                        {
                            selectvalue: "landscape",
                            selecttext: "Landscape",
                        },
                        {
                            selectvalue: "portrait",
                            selecttext: "Portrait",
                        }                        
                    ],
            firstNameRules: [
                fieldNotEmpty,            
                (v) => fieldMinLength(v, 2)   
            ],
            codeRules: [
                fieldNotEmpty,            
                (v) => fieldMinLength(v, 2)  
            ]
        }
    },
    computed: {
        status() {
            return (this.disabled) ? "Disabled" :
                    (this.invitation_code) ? "Invited" :
                    (this.activation_date === "" || this.activation_date == null) ? "Awaiting Activation" : "Active";                    
        }
    },
    created() {
        if(this.$route.params.code) {
            this.crud_code = this.$route.params.code;       
            this.createMode = false;     
        }
        else {            
            this.createMode = true;
        }
        
    },
    async mounted() {       
        if(!this.createMode) {
            const rspB = await api.devicetype.getOne(this.crud_code);
            if(rspB && rspB.data) {
                this.code = rspB.data.code;
                this.title = rspB.data.title;
                this.description = rspB.data.description;
                this.orientation = rspB.data.orientation;            
            }
            //this.editFormValid = true;
        }         
    },
    methods: {
        save() {
            this.error = '';     
            this.$refs.editForm.validate();                          
            if(!this.editFormValid) {                
                this.snackbarColor = "red";
                this.snackbarMessage = "There are invalid fields.";
                this.snackbar = true;
                return;
            }                                
            this.loading = true;   
            if(this.createMode) {
                api.devicetype.create({      
                    code: this.code,                          
                    title: this.title,
                    description: this.description,
                    orientation: this.orientation,              
                })
                .then((rsp)=>{               
                    //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                    //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});
                    // this.snackbarColor = "green";
                    // this.snackbarMessage = "User updated successfully";
                    // this.snackbar = true;  
                    this.$router.push("/mng/adpoints/devicetypes/");
                })
                .catch((error)=>{
                    //this.error = error.response.data.message;
                    this.snackbarColor = "red";
                    this.snackbarMessage = error.response.data.message;
                    this.snackbar = true;
                })
                .finally(()=>{
                    this.loading = false;
                })
            }  
            else {
                api.devicetype.update(this.crud_code, {                                
                    title: this.title,
                    description: this.description,
                    orientation: this.orientation,               
                })
                .then((rsp)=>{               
                    //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                    //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});
                    // this.snackbarColor = "green";
                    // this.snackbarMessage = "User updated successfully";
                    // this.snackbar = true;  
                    this.$router.push("/mng/adpoints/devicetypes/");
                })
                .catch((error)=>{
                    //this.error = error.response.data.message;
                    this.snackbarColor = "red";
                    this.snackbarMessage = error.response.data.message;
                    this.snackbar = true;
                })
                .finally(()=>{
                    this.loading = false;
                })
            }                 
        },       
    }
}
</script>
<style lang="scss">
.ul-widget-profile-img {
    position: relative;
}
.ul-widget-profile-img:after {
    // content: "";
    // position: absolute;
    // top: 44px;
    // left: 50%;
    // width: 1px;
    // height: calc(100% - 30px);
    // background: #B3C0CE;
    content: '';
    position: absolute;
    top: 44px;
    left: 50%;
    width: 1px;
    height: calc(100% - 30px);
    background: #b3c0ce;
}
</style>
