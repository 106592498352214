<template>
    <div>
        <v-sheet class="px-6 pt-6" v-if="!invite_mode">
            <div class="container">
                <div class="d-flex flex-wrap justify-space-between mb-8">
                    <v-avatar size="120" class="mr-6">
                        <img
                            class=""
                            src="@/assets/images/avatars/001-man.svg"
                            alt=""
                        />
                    </v-avatar>
                    <div class="flex-1">
                        <div class="d-flex align-start justify-space-between">
                            <div class="mb-3">
                                <h5 class="mr-2 mb-0">
                                    {{ firstName }} {{ lastName }}
                                    <v-chip
                                        v-if="status === 'Active'"
                                        class="ma-2"
                                        color="green"  
                                        dark                                  
                                        small
                                    >
                                        <v-icon small left>mdi-check</v-icon>
                                        Active
                                    </v-chip>
                                    <v-chip
                                        v-if="status === 'Disabled'"
                                        class="ma-2"
                                        color="error"  
                                        dark                                  
                                        small
                                    >
                                        <v-icon small left>mdi-close</v-icon>
                                        Disabled
                                    </v-chip>
                                    <v-chip
                                        v-if="status === 'Invited'"
                                        class="ma-2"
                                        color="warning"  
                                        dark                                  
                                        small
                                    >
                                        <v-icon small left>mdi-email</v-icon>
                                        Invited
                                    </v-chip>
                                    <v-chip
                                        v-if="status === 'Awaiting Activation'"
                                        class="ma-2"
                                        color="info"  
                                        dark                                  
                                        small
                                    >
                                        <v-icon small left>mdi-timer-sand</v-icon>
                                        Awaiting Activation
                                    </v-chip>
                                </h5>
                                <span></span>                                
                                <p class="text--disabled mb-0">{{ email }}</p>
                            </div>
                            <div>
                                
                            </div>
                        </div>
                        <div class="d-flex mb-2">
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium">{{ role }}</span
                                >
                                <!-- <span class="body-2 text--disabled"
                                    >Posts</span
                                > -->
                            </p>
                        </div>
                        <p class="text--disabled">
                            {{ selected_brands_descr }}
                        </p>
                    </div>
                    <div class="flex-1 text-right">
                        <v-btn class="ma-2" dark color="success" :loading="loading" @click="save">
                            <v-icon class="pr-2">mdi-content-save</v-icon>
                            Save
                        </v-btn>
                    </div>
                </div>
            </div>


            <!-- <v-row justify="center">
            <v-col cols="10" md="5">
                <e-info-card
                    title="Activity and timeline"
                    sub-title="See the activity saved in your account and the places you've been. You can delete any or all your past activity."
                >
                    <template slot="basic-info-slot">
                        <v-list>
                            <v-list-item
                                v-for="item in items2"
                                :key="item.title"
                            >
                                <v-list-item-avatar>
                                    <img :src="item.icon" alt="" />
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="item.title"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                        v-text="item.subtitle"
                                    ></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-information</v-icon
                                        >
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </template>
                </e-info-card>
            </v-col>
        </v-row> -->

        </v-sheet>

        <!-- <v-row justify="center">
            <v-col cols="10" md="5">
                <e-info-card
                    title="Account"
                    sub-title="Authentication & Credentials"
                >
                    <template slot="basic-info-slot">
                        <v-list>
                            <v-list-item                                
                            >
                                <v-list-item-avatar>
                                    <img :src="''" alt="" />
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >Title</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                        v-text="'Subtitle'"
                                    ></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-information</v-icon
                                        >
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </template>
                </e-info-card>
            </v-col>
        </v-row> -->


        <v-row justify="center">            
            <v-col cols="10" md="5" v-if="!invite_mode">
                <e-info-card
                    title="Account"
                    sub-title="Profile & Authentication"
                >
                    <template slot="basic-info-slot">
                        <v-form  v-model="editFormValid" ref="editForm">
                            <v-list>
                                <v-list-item>
                                    <!-- <v-list-item-avatar>
                                        <img :src="''" alt="" />
                                    </v-list-item-avatar> -->

                                    
                                    <v-list-item-content class="pb-0">
                                        <v-text-field 
                                            label="Firstname" 
                                            v-model="firstName"    
                                            :rules="firstNameRules"
                                            :validate-on-blur="false"
                                            class="col-6 p-0 pr-4"
                                            style="padding: 0px 0px;"
                                        />
                                        <v-text-field 
                                            label="Lastname" 
                                            v-model="lastName"  
                                            :rules="lastNameRules"  
                                            :validate-on-blur="false"                          
                                            class="col-6 p-0 "
                                            style="padding: 0px 0px;"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                               
                                    <v-list-item-content class="pt-0">
                                        <v-select                                                                     
                                            v-model="role"
                                            :items="roles_list_items"                 
                                            label="Role" 
                                            item-text="selecttext"
                                            item-value="selectvalue"   

                                            :rules="roleRules"     
                                            required                                                         
                                            :clearable="true"                      
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                             
                                    <v-list-item-content>
                                        <v-text-field 
                                            label="Email" 
                                            v-model="email"
                                            :rules="emailRules"      
                                            :validate-on-blur="false"
                                        />
                                    </v-list-item-content>                                
                                </v-list-item>
                                <v-list-item>                                                                
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <span >Reset Password</span>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Click the button to send a 'Reset Password' email to the user.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <!-- <v-btn icon>
                                            <v-icon color="grey lighten-1"
                                                >mdi-information</v-icon
                                            >
                                        </v-btn> -->
                                        <v-btn dark color="danger" small class="mr-5" :loading="loading" @click="reset">
                                            Reset
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-if="!disabled">                                                                
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <span >Disable Account</span>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            User will not be able to access the platform while disabled
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <!-- <v-btn icon>
                                            <v-icon color="grey lighten-1"
                                                >mdi-information</v-icon
                                            >
                                        </v-btn> -->
                                        <v-btn dark color="danger" small class="mr-5" :loading="loading" @click="deactivate">
                                            Disable
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-if="disabled">                                                                
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <span >Enable Account</span>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            User will be enabled.
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <!-- <v-btn icon>
                                            <v-icon color="grey lighten-1"
                                                >mdi-information</v-icon
                                            >
                                        </v-btn> -->
                                        <v-btn dark color="success" small class="mr-5" :loading="loading" @click="activate">
                                            Enable
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                    

                                <!-- <v-list-item>                                
                                    <v-list-item-avatar>
                                        <img :src="''" alt="" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <span >Title</span>
                                        </v-list-item-title>
                                        <v-list-item-subtitle
                                            v-text="'Subtitle'"
                                        ></v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-btn icon>
                                            <v-icon color="grey lighten-1"
                                                >mdi-information</v-icon
                                            >
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item> -->
                            </v-list>
                        </v-form>
                    </template>
                </e-info-card>
            </v-col>
            <!-- <v-col cols="10" md="5" v-if="!invite_mode">
                <e-info-card
                    title="Brands"
                    sub-title="The Brands that this account is associated with"
                >
                    <template slot="basic-info-slot">
                        <v-list>
                            <v-list-item>                                
                                    <v-list-item-content>
                                        <v-select                                                                     
                                                v-model="brand_to_add"
                                                :items="brand_list_items"                 
                                                label="Select a Brand and hit the 'Add' button" 
                                                item-text="selecttext"
                                                item-value="selectvalue"                                             
                                                hide-details
                                                single-line                                                                   
                                                :clearable="true"                      
                                            />
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-btn class="ma-2" dark color="primary" small @click="addSelectedBrand">
                                            <v-icon>mdi-plus</v-icon>
                                            Add
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                <div v-if="selected_brands && selected_brands.length">
                                    <v-list-item v-for="b in selected_brands" :key="b.code">
                                        <v-list-item-avatar>
                                            <img :src="'/img/database.c82dbe43.svg'" alt="" />
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span >{{ b.title }}</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ b.description }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-btn
                                                color="danger"
                                                dark                                                                                
                                                icon
                                                @click="removeBrand(b.code)"
                                            >
                                                <v-icon>mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </div>
                                <div v-else>
                                    <v-list-item>                                
                                        <v-list-item-content class="text-center">
                                            <div class="flex-1 text-center">
                                                No Brands selected. Add one or more brands.
                                            </div>                                           
                                        </v-list-item-content>
                                    </v-list-item>
                                </div>
                        </v-list>
                    </template>
                </e-info-card>
            </v-col> -->
            <v-col cols="10" md="5" v-if="!invite_mode">
                <e-info-card
                    title="Other Information"                    
                >
                    <template slot="basic-info-slot">
                        <v-list>                            
                            <v-list-item>                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >User Code</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ crud_code }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >Invitation Code</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ invitation_code }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >Invitation Created On</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ invitation_created_date }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >Activation Link</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Click the button to visit the activation link
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn class="ma-2" dark color="primary" small :href="'/auth/activation/' + activation_code " target="_blank">
                                        Visit
                                    </v-btn>
                                </v-list-item-action>                                
                            </v-list-item>
                            <v-list-item>                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >Activation Date</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ activation_date }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <!-- <v-list-item>                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span >Last Password Reset</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        2015-07-01
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item> -->
                        </v-list>
                    </template>
                </e-info-card>
            </v-col>
        </v-row>
        <!-- <base-card class="transparent">
            
        </base-card> -->
        <v-snackbar
            v-model="snackbar"
            :timeout="4000"
            top
            tile
            :color="snackbarColor + ' -accent-2'"            
            >
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
</template>
<script>
import avatarGroupCard from '@/components/card/AvatarGroupCard';
import EInfoCard from '@/components/card/BasicInfoCard';
import { api } from "src/api/index";
import { fieldNotEmpty, fieldMinLength } from "src/core/vuetify-validators.js"

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'User Edit'
    },
    components: {
        avatarGroupCard,
        EInfoCard
    },
    data() {
        return {
            //primary data
            email: '',
            firstName: '',
            lastName: '',
            role: '',
            brand: [],
            invitation_code: '',
            invitation_created_date: '',
            activation_code: '',
            activation_date: '',
            crud_code: '',
            disabled: false,
            //ui mechanics            
            invite_mode: false, 
            invitationFormValid: true,
            editFormValid: true,
            brand_to_add: '',
            //error
            loading: false,
            snackbar: false,
            snackbarColor: 'green',
            snackbarMessage: '',
            error: '',
            //reference data
            brand_list_items: [],                                    
            roles_list_items: [
                        {
                            selectvalue: "admin",
                            selecttext: "Admin",
                        },
                        // {
                        //     selectvalue: "viewer",
                        //     selecttext: "Viewer",
                        // },
                        // {
                        //     selectvalue: "designer",
                        //     selecttext: "Designer",
                        // },
                        // {
                        //     selectvalue: "dev",
                        //     selecttext: "Developer",
                        // },
                        {
                            selectvalue: "sysadmin",
                            selecttext: "System Admin",
                        }
                    ],
            emailRules: [
                fieldNotEmpty,
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                (v) => fieldMinLength(v, 5)
            ],
            firstNameRules: [
                fieldNotEmpty,            
                (v) => fieldMinLength(v, 2)   
            ],
            lastNameRules: [
                fieldNotEmpty,            
                (v) => fieldMinLength(v, 2)   
            ],
            roleRules: [v => !!v || 'Item is required'],            
        }
    },
    computed: {
        selected_brands() {     
            if(this.brand && this.brand_list_items) {
                const rslt = this.brand_list_items.filter(i=> this.brand.includes(i.code));                
                if(rslt) {
                    return rslt;
                }                                           
            }                   
            return [];
        },
        selected_brands_descr() {     
            if(this.brand && this.brand_list_items) {
                const rslt = this.brand_list_items.filter(i=> this.brand.includes(i.code));                
                if(rslt) {
                    return rslt.map(i=>i.title).join(', ');
                }                                           
            }                   
            return "";
        },
        status() {
            return (this.disabled) ? "Disabled" :
                    (this.invitation_code) ? "Invited" :
                    (this.activation_date === "" || this.activation_date == null) ? "Awaiting Activation" : "Active";                    
        }
    },
    created() {
        if(this.$route.params.code) {
            this.crud_code = this.$route.params.code;
            this.invite_mode = false;
        }
        else {
            this.invite_mode = true;
        }
        
    },
    async mounted() {
        const rsp = await api.brand.getAll();            
        this.brand_list_items = rsp.data.map(i=> {return {...i, selectvalue: i.code, selecttext: i.title}});
        const rspB = await api.users.getUser(this.crud_code);
        if(rspB && rspB.data) {
            this.email = rspB.data.email;
            this.firstName = rspB.data.firstName;
            this.lastName = rspB.data.lastName;
            this.role = rspB.data.role;
            this.brand = rspB.data.brand;
            this.crud_code = rspB.data.code;
            this.invitation_code = rspB.data.invitation_code;
            this.invitation_created_date = rspB.data.invitation_created_date;            
            this.activation_code = rspB.data.activation_code;
            this.activation_date = rspB.data.activation_date;
            this.disabled = rspB.data.disabled;
        }
        this.editFormValid = true;
    },
    methods: {
        addSelectedBrand() {
            if(!this.brand) {
                this.brand = [];
            }
            if(!this.brand.includes(this.brand_to_add)){
                this.brand.push(this.brand_to_add);                
            }
            this.brand_to_add = "";            
        },
        removeBrand(b){
            for(var i = 0; i < this.brand.length; i++){             
                if ( this.brand[i] === b) {             
                    this.brand.splice(i, 1); 
                }            
            }
        },
        invite() {
            this.error = '';
            if(!this.invitationFormValid) {
                this.snackbarColor = "red";
                this.snackbarMessage = "There are invalid fields";
                this.snackbar = true;
                return;
            }                                
            this.loading = true;          
            api.users.invite({                
                email: this.email,                                
                firstName: this.firstName,
                lastName: this.lastName,
                role: this.role,
                brand: this.brand
            })
            .then((rsp)=>{               
                //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});  
            })
            .catch((error)=>{
                //this.error = error.response.data.message;
                this.snackbarColor = "red";
                this.snackbarMessage = error.response.data.message;
                this.snackbar = true;
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        save() {
            this.error = '';                               
            if(!this.editFormValid) {
                this.$refs.editForm.validate();
                this.snackbarColor = "red";
                this.snackbarMessage = "There are invalid fields.";
                this.snackbar = true;
                return;
            }                                
            this.loading = true;          
            api.users.update(this.crud_code, {                
                email: this.email,                                
                firstName: this.firstName,
                lastName: this.lastName,
                role: this.role,
                brand: this.brand
            })
            .then((rsp)=>{               
                //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});
                // this.snackbarColor = "green";
                // this.snackbarMessage = "User updated successfully";
                // this.snackbar = true;  
                this.$router.push("/mng/system/users/");
            })
            .catch((error)=>{
                //this.error = error.response.data.message;
                this.snackbarColor = "red";
                this.snackbarMessage = error.response.data.message;
                this.snackbar = true;
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        deactivate() {
            //alert("adsfa");
            this.error = '';                                                           
            this.loading = true;          
            api.users.disable(this.crud_code, {                
                disabled: true
            })
            .then((rsp)=>{               
                //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});
                this.disabled=true;
                this.snackbarColor = "green";
                this.snackbarMessage = "User is now Disabled";
                this.snackbar = true;  
                //this.$router.push("/mng/system/users/");
            })
            .catch((error)=>{
                //this.error = error.response.data.message;
                this.snackbarColor = "red";
                this.snackbarMessage = error.response.data.message;
                this.snackbar = true;
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        activate() {
            this.error = '';                                                           
            this.loading = true;          
            api.users.disable(this.crud_code, {                
                disabled: false
            })
            .then((rsp)=>{               
                //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});
                this.disabled=false;
                this.snackbarColor = "green";
                this.snackbarMessage = "User is now Enabled";
                this.snackbar = true;  
                //this.$router.push("/mng/system/users/");
            })
            .catch((error)=>{
                //this.error = error.response.data.message;
                this.snackbarColor = "red";
                this.snackbarMessage = error.response.data.message;
                this.snackbar = true;
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        reset() {
            this.error = '';                                                           
            this.loading = true;          
            api.users.reset(this.crud_code, {                
                disabled: false
            })
            .then((rsp)=>{               
                //this.$router.push("/mng/system/users/edit/" + rsp.data.code);  
                //this.$router.push({ name: "system-users-edit", params: {code: rsp.data.code}});
                this.disabled=false;
                this.snackbarColor = "green";
                this.snackbarMessage = "Password reset email sent.";
                this.snackbar = true;  
                //this.$router.push("/mng/system/users/");
            })
            .catch((error)=>{
                //this.error = error.response.data.message;
                this.snackbarColor = "red";
                this.snackbarMessage = error.response.data.message;
                this.snackbar = true;
            })
            .finally(()=>{
                this.loading = false;
            })
        },
    }
}
</script>
<style lang="scss">
.ul-widget-profile-img {
    position: relative;
}
.ul-widget-profile-img:after {
    // content: "";
    // position: absolute;
    // top: 44px;
    // left: 50%;
    // width: 1px;
    // height: calc(100% - 30px);
    // background: #B3C0CE;
    content: '';
    position: absolute;
    top: 44px;
    left: 50%;
    width: 1px;
    height: calc(100% - 30px);
    background: #b3c0ce;
}
</style>
