<template>
    <v-row>
        <v-col>            
            <v-row>
                <v-col cols="12">
                    <base-card>
                        <v-card-title>                                                        
                            <div class="d-flex justify-space-between flex">
                                <div class="d-flex justify-center pb-2 px-3 pt-1">   
                                    <img class="ma-4 mx-0" style="display: inline-block" src="/icon-small.jpg" height="34" />
                                    <h5 class="ma-5 ml-2" style="display: inline-block" >Campaign Progress</h5> 
                                </div> 
                                <v-autocomplete
                                    class="ma-4 mb-0 d-flex flex"
                                    v-model="selectedCampaign"
                                    :items="campaignOptions"
                                    label="Select a Campaign..."      
                                    outlined       
                                    dense                                                      
                                ></v-autocomplete>
                                <v-btn class="ma-4" dark color="primary" @click="fetchSelectedCampaignStatistics(selectedCampaign)" :loading="progressStatsLoading">
                                        <v-icon>mdi-reload</v-icon>
                                        Reload
                                </v-btn>
                            </div>
                        </v-card-title>                                        
                        <v-progress-linear
                            :active="progressStatsLoading"
                            :indeterminate="progressStatsLoading"
                            absolute
                            bottom
                            color="deep-purple accent-4"
                        />
                    </base-card>
                </v-col>
            </v-row>
            <v-row v-if="this.selectedCampaign == null">
                <v-col>
                    <base-card>
                        <v-card-text>
                            <div class="d-flex justify-center mt-2" style="width: 100%">
                                    Please Select a Campaign                                
                            </div>  
                        </v-card-text>
                    </base-card>
                </v-col>
            </v-row>
            <v-row v-if="this.selectedCampaign != null">
                <v-col>
                    <base-card>
                        <v-card-title>Events</v-card-title>
                        <v-card-text>
                            <apexchart
                                type="line"
                                height="350"
                                :options="getEventActivityGraphData(progressStats).chartOptions"
                                :series="getEventActivityGraphData(progressStats).series"
                            />
                        </v-card-text>
                    </base-card>
                </v-col>
            </v-row>
            <template v-for="(stat, idx) in progressStats" >
            <v-row :key="idx" >
                <v-col cols="12">
                    <base-card>
                        <v-card-title>                                                        
                            <div class="d-flex justify-space-between flex">
                                <div class="d-flex justify-center px-3 pt-2">                                       
                                    <h5 class="ml-2" style="display: inline-block" ><a target="_blank" :href="stat.assetUrl"><img style="vertical-align: middle;" :src="stat.assetThumbnail"  alt="" width="60" /></a> <span class="ml-10">{{stat.type}}</span></h5> 
                                </div>     
                                <div class="d-flex justify-center  pt-2">                                       
                                    <a target="_blank" :href="stat.assetUrl"><h5 class="mr-4" style="display: inline-block; color: gray"  >{{stat.assetName}}</h5></a>
                                </div>                              
                            </div>
                        </v-card-title>                                        
                    
                    </base-card>
                </v-col>
            </v-row>
            <v-row :key="idx+'2a'">
                <v-col cols="3">
                    <base-card>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                <div class="body-2 mb-0 text--disabled">
                                    Goal: {{stat.total_target}}
                                </div>
                                <v-list-item-title class="headline mb-1 font-weight-bold">{{stat.total_actual}}</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                    <div class="d-flex flex-wrap">
                                        <span class="font-weight-bold text-info"
                                            >Current Total Impressions</span
                                        >
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <apexchart
                                width="100"
                                height="120"
                                type="radialBar"
                                :options="getRatioWidgetData(stat.total_actual, stat.total_target).chartOptions"
                                :series="getRatioWidgetData(stat.total_actual, stat.total_target).series"
                            />
                        </v-list-item>
                    </base-card>
                </v-col>
                <v-col cols="3">
                    <base-card>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                <div class="body-2 mb-0 text--disabled">
                                    Target Daily Avg.: {{stat.daily_avg_target}}
                                </div>
                                <v-list-item-title
                                    class="headline mb-1 font-weight-bold"
                                    >{{stat.today_actual}}</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                    <div class="d-flex flex-wrap">
                                        <span class="font-weight-bold text-info"
                                            >Today's Total</span
                                        >
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <!-- <v-sheet
                                height="60"
                                width="60"
                                class="primary lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                            >
                                <v-icon style="color: red;"
                                    >mdi-arrow-down</v-icon
                                >
                            </v-sheet> -->
                        </v-list-item>
                    </base-card>
                </v-col>
                <v-col cols="3">
                    <base-card>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                <div class="body-2 mb-0 text--disabled">
                                    Target Daily Avg.: {{stat.daily_avg_target}}
                                </div>
                                <v-list-item-title
                                    class="headline mb-1 font-weight-bold"
                                    >{{stat.daily_avg_actual}}</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                    <div class="d-flex flex-wrap">
                                        <span class="font-weight-bold text-info"
                                            >Actual Daily Avg. (All Time)</span
                                        >
                                    </div>
                                </v-list-item-subtitle>

                            </v-list-item-content>
                            <v-sheet
                                v-if="stat.daily_avg_actual >= stat.daily_avg_target"
                                height="60"
                                width="60"
                                class="success lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                            >
                                <v-icon style="color: green;"
                                    >mdi-arrow-up</v-icon
                                >
                            </v-sheet>
                            <v-sheet
                                v-else
                                height="60"
                                width="60"
                                class="primary lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                            >
                                <v-icon style="color: red;"
                                    >mdi-arrow-down</v-icon
                                >
                            </v-sheet>
                        </v-list-item>
                    </base-card>
                </v-col>
                <v-col cols="3">
                    <base-card>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                <div class="body-2 mb-0 text--disabled">
                                    Target Daily Avg.: {{stat.daily_avg_last3days_target}}
                                </div>
                                <v-list-item-title
                                    class="headline mb-1 font-weight-bold"
                                    >{{stat.daily_avg_last_3days_actual}}</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                    <div class="d-flex flex-wrap">
                                        <span class="font-weight-bold text-info"
                                            >Actual Daily Avg. (Last 3 Days)</span
                                        >
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-sheet
                                v-if="stat.daily_avg_last_3days_actual >= stat.daily_avg_last3days_target"
                                height="60"
                                width="60"
                                class="success lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                            >
                                <v-icon style="color: green;"
                                    >mdi-arrow-up</v-icon
                                >
                            </v-sheet>
                            <v-sheet
                                v-else
                                height="60"
                                width="60"
                                class="primary lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                            >
                                <v-icon style="color: red;"
                                    >mdi-arrow-down</v-icon
                                >
                            </v-sheet>
                        </v-list-item>
                    </base-card>
                </v-col>                                
            </v-row>            
            <v-row :key="idx+'2b'">
                <v-col cols="12">
                    <base-card>
                        <div class="d-flex align-center justify-space-between pr-3">
                            <v-card-title>Goal Tracking - {{stat.type}}</v-card-title>
                        </div>
                        <v-card-text>
                            <apexchart
                            type="line"
                            width="100%"
                            height="365"
                            :options="getGoalTrackingGraphData(stat).chartOptions"
                            :series="getGoalTrackingGraphData(stat).series"
                            />
                        </v-card-text>
                    </base-card>
                </v-col>                                            
            </v-row>
            </template>
            
            <v-row v-if="this.selectedCampaign != null">
                <v-col cols="12">
                    <base-card>
                        <v-card-title>                                                        
                            <div class="d-flex justify-space-between flex">
                                <div class="d-flex justify-center pb-2 px-3 pt-1">   
                                    <!-- <img class="ma-4 mx-0" style="display: inline-block" src="/icon-small.jpg" height="34" /> -->
                                    <h5 class="ma-5 ml-2" style="display: inline-block" >Campaign Activity</h5> 
                                </div> 
                                
                                <v-autocomplete
                                    class="ma-4 mb-0 d-flex flex"
                                    v-model="selectedCalcInterval"
                                    :items="[/*'Custom Date Range',*/'Last 30 Days','Last 20 Days','Last 15 Days','Last 10 Days','Last 7 Days','Last 5 Days','Last 3 Days', 'Yesterday']"
                                    label="Period Shown"      
                                    outlined       
                                    dense                                                      
                                ></v-autocomplete>
                                <v-menu
                                    v-if="selectedCalcInterval === 'Custom Date Range'"
                                    ref="calcDatepicker"
                                    v-model="calculationDatepicker"
                                    :close-on-content-click="false"
                                    
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{on, attrs}">
                                        <v-text-field
                                        class="ma-4 mb-0 d-flex flex"
                                            label='Select Date Range'
                                            dense
                                            outlined
                                            v-model="calculationDate"                                              
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details    
                                            :clearable="true"                                                       
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="calculationDate" scrollable range>                    
                                    </v-date-picker>
                                </v-menu>


                                <v-btn class="ma-4" dark color="primary" @click="fetchCampaignActivityAdpoints(selectedCampaign)" :loading="activityStatsLoading">
                                        <v-icon>mdi-reload</v-icon>
                                        Reload
                                </v-btn>
                            </div>
                        </v-card-title>                                        
                        <v-progress-linear
                            :active="activityStatsLoading"
                            :indeterminate="activityStatsLoading"
                            absolute
                            bottom
                            color="deep-purple accent-4"
                        />
                    </base-card>
                </v-col>
            </v-row>
            <v-row v-if="this.selectedCampaign != null">
                <v-col>
                    <v-data-table                                     
                        :headers="headers_adp"
                        :options.sync="pagingOptions_adp"
                        :server-items-length="totalItemCount_adp"
                        :items="gridData_adp"
                        item-key="adpointid"                                     
                        class="table-one"
                        multi-sort
                    >             
                        <template v-slot:[`header.data-table-select`]></template>       
                        <!-- <template v-slot:item.fullname="{item}">
                            <div class="d-flex align-center">
                                <v-avatar class="mr-2" size="26" dark>
                                    <img src="@/assets/images/avatars/001-man.svg" alt="" />
                                </v-avatar>
                                <p class="ma-0 font-weight-medium">
                                    {{ item.fullname }}
                                </p>
                            </div>
                        </template> -->
                        <template v-slot:item.blob="{item}">
                            <div class="d-flex align-center">                        
                                <p class="ma-0 font-weight-medium">
                                    {{JSON.stringify(item.blob)}}
                                </p>
                            </div>
                        </template>
                        <!-- <template v-slot:item.clickeventscount="{item}">
                            <div class="d-flex align-center">                        
                                <p class="ma-0 font-weight-medium">
                                    {{generateRandomInteger(8,28)}}
                                </p>
                            </div>
                        </template> -->
                        

                        <template v-slot:item.action="{item}">
                            <div class="d-flex">
                            </div>
                        </template>
                        <template v-slot:item.status="{item}">
                            <template v-if="item.status != 'offline'">
                                <v-chip
                                    class=""    
                                    color="success"                            
                                    label
                                    small
                                    text-color="white"
                                >
                                    <v-icon small left>mdi-check</v-icon>
                                    {{ item.status }}
                                </v-chip>
                            </template>                        
                            <template v-else>
                                <v-chip
                                    class=""
                                    color="error"
                                    label
                                    small
                                    text-color="white"
                                >
                                    <v-icon small left>mdi-close</v-icon>
                                    {{ item.status }}
                                </v-chip>
                            </template>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            
            
            
            <base-card v-if="this.selectedCampaign != null">                            
                <v-data-table
                    v-model="selected"                    
                    :headers="headers"
                    :options.sync="pagingOptions"
                    :server-items-length="totalItemCount"
                    :items="gridData"
                    item-key="id"
                    :show-select="false"
                    class="elevation-1 table-one"
                    multi-sort                    
                >
                    <template v-slot:item.aud_create_date="{item}">
                        <div class="d-flex align-center">                        
                            <p class="ma-0 font-weight-medium">
                                {{ item.aud_create_date | formatDate }}
                            </p>
                        </div>
                    </template>

                    <template v-slot:item.data="{item}">
                        <div class="d-flex align-center">                        
                            <p class="ma-0 font-weight-medium">
                                {{ JSON.stringify(item.data) }}
                            </p>
                        </div>
                    </template>

                    <template v-slot:item.eventtype="{item}">
                        <div class="d-flex align-center">                        
                            <p class="ma-0 font-weight-medium" v-if="item.type === 'impression'">
                                {{ item.type }}
                            </p>
                            <p class="ma-0 font-weight-medium" v-else>
                                {{ item.name }}
                            </p>
                        </div>
                    </template>
                    

                    <template v-slot:item.action="{item}">
                        <div class="d-flex">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        :to="'/mng/system/users/edit/' + item.code"
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.offline="{item}">
                        <template v-if="item.status !== 'true'">
                            <v-chip
                                class=""    
                                color="success"                            
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-check</v-icon>
                                online
                            </v-chip>
                        </template>                        
                        <template v-else>
                            <v-chip
                                class=""
                                color="error"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-close</v-icon>
                                offline
                            </v-chip>
                        </template>
                    </template>
                </v-data-table>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import { api } from "src/api/index";
import moment from "moment";
import AdvancedGridFiltersList from "@/components/core/AdvancedGridFiltersList";
import {
    gradientLineChart,
    salesByCountries,
    visitorsAnalytic
} from '@/data/sales2';
import {
  analyticOne,
  analyticTwo,
  analyticThree,
  analyticFour,
  analyticFive,
} from "@/data/analytic2";

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Adpoints Management'
    },    
    components: {
        "advanced-grid-filters-list": AdvancedGridFiltersList        
    },
    data() {
        return {
            analyticFour : {
                series: [
                    {
                    name: 'Actual',
                    data: [null, null, null, null, null, null, null, null, null] //[0, 200, 213*2 + 15, 213*3 + 70, 213*4 - 43, 213*5 - 190, 213*6 - 84, 213*7 - 170, 213*8 - 250],
                    },
                    {
                    name: 'Goal',
                    data: [0] //[0, 213, 213*2, 213*3, 213*4, 213*5, 213*6, 213*7, 213*8, 213*9, 213*10, 213*11, 213*12, 213*13, 213*14, 213*15, null, null, null, null, null],
                    },
                ],
                chartOptions: {
                    chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2,
                    },
                    toolbar: {
                        show: false,
                    },
                    },
                    colors: ['#28C76E', '#8e8da2'],
                    stroke: {
                    width: [4, 3],
                    curve: 'straight',
                    },
                    markers: {
                    size: [6, 0],
                    },
                    dataLabels: {
                    enabled: false,
                    },
                    grid: {
                    strokeDashArray: 3,
                    },
                    xaxis: {
                    categories: [
                        'Day 1',
                        'Day 2',
                        'Day 2',
                        'Day 4',
                        'Day 5',
                        'Day 6',
                        'Day 7',
                        'Day 8',
                        'Day 9',
                        'Day 10',
                        'Day 11',
                        'Day 12',
                        'Day 13',
                        'Day 14',
                        'Day 15',
                        'Day 16',
                        'Day 17',
                        'Day 19',
                        'Day 20',                    
                    ],
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    },
                },            

            },


            analyticFourAB : {
                series: [
                    {
                    name: 'Actual',
                    data: [0, 63, 63*2 + 15, 63*3 + 70, 63*4 + 89, 63*5 + 30, 80*6 - 84, 80*7 - 41, 80*8 - 23],
                    },
                    {
                    name: 'Goal',
                    data: [0, 63, 63*2, 63*3, 63*4, 63*5, 63*6, 63*7, 63*8, 63*9, 63*10, 63*11, 63*12, 63*13, 63*14, 63*15, null, null, null, null, null],
                    },
                ],
                chartOptions: {
                    chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2,
                    },
                    toolbar: {
                        show: false,
                    },
                    },
                    colors: ['#28C76E', '#8e8da2'],
                    stroke: {
                    width: [4, 3],
                    curve: 'straight',
                    },
                    markers: {
                    size: [6, 0],
                    },
                    dataLabels: {
                    enabled: false,
                    },
                    grid: {
                    strokeDashArray: 3,
                    },
                    xaxis: {
                    categories: [
                        'Day 1',
                        'Day 2',
                        'Day 2',
                        'Day 4',
                        'Day 5',
                        'Day 6',
                        'Day 7',
                        'Day 8',
                        'Day 9',
                        'Day 10',
                        'Day 11',
                        'Day 12',
                        'Day 13',
                        'Day 14',
                        'Day 15',
                        'Day 16',
                        'Day 17',
                        'Day 19',
                        'Day 20',                    
                    ],
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    },
                },            

            },
            // gridConfig: {
            //     rowActions: [
            //         {
            //             title: "Edit",
            //             action: "localMethodNameGoesHere",
            //             icon: "iconGoesHere",
            //         },
            //         {
            //             title: "Delete",
            //             action: "localMethodNameGoesHere",
            //             icon: "iconGoesHere",
            //         },
            //     ],
            //     columns: [
            //         {title: "Name", 
            //         type: "text", 
            //         bindings: {
            //             datafield: "fullname",
            //             prependIcon: "",
            //             appendIcon: "",
            //             formatter: (rowData) => {
            //                 return v;
            //             },
            //             statusMapper: (rowData) => {
            //                 return {
            //                     color: "",
            //                     icon: "",
            //                     text: ""
            //                 }
            //             },

            //         }}
            //     ]
            // },
            gradientLineChart,
            gradientLineChart2: {
  series: [
    {
      name: 'impression',
      data: [null, null, null, null, null, null, null], //[14, 29, 18, 30, 20, 40, 36],
    },
    {
      name: 'click',
      data: [null, null, null, null, null, null, null],
    },
  ],
  chartOptions: {
    chart: {
      type: 'line',
      height: 350,
   
      toolbar: {
        show: false,
      },
    },
    dropShadow: {
      enabled: true,
      color: '#000',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 1,
    },
    colors: ['#5d78ff', '#fbaf0f', '#FF5CD3', '#444CF2', '#48DBBB', '#A6F244', '#FBAF0F'],
    stroke: {
      width: 7,
      curve: 'smooth',
    },

    xaxis: {
      categories: ['Thu 30/06', 'Fri 01/07', 'Sat 02/07', 'Sun 03/07', 'Mon 04/07', 'Tue 05/07', 'Wed 06/07'],
      title: {
        text: '',
      },
      axisBorder: {
          show: false,
        },
    },
    yaxis: {
      title: {
        text: '',
      },
      min: 5,
      max: 40,
    },
    markers: {
      size: 5,
      hover: {
        size: 7,
      },
    },
    grid: {
      
      borderColor: 'transparent',
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  },
},
            // selectedNetwork: null,
            // networkOptions: [],
            selectedCampaign: null,
            progressStats: null,
            progressStatsLoading: false,
            activityStatsLoading: false,

            campaignOptions: [],
            attributeOptions: [],
            brandOptions: [],
            deviceTypeOptions: [],
            selectedCalcInterval: 'Last 7 Days',
            calculationDate: [],
            calculationDatepicker: null,
            attributeToAdd: null,








            series: [70],
            chartOptions: {
                chart: {
                height: 120,
                type: "radialBar"
                },
                
            
                colors:["#33cc33"],
                plotOptions: {
                radialBar: {
                    hollow: {
                    margin: 0,
                    size: "40%"
                    },
                
                    dataLabels: {
                    show: true,
                    name: {
                        offsetY: 0,
                        show: false,
                        color: "green",
                        fontSize: "13px"
                    },
                    value: {
                        offsetY: 6,
                        color: "#828d99",
                        fontSize: "15px",
                        show: true,
                        fontWeight: 700,
                    }
                    }
                }
                },
            
                stroke: {
                lineCap: "round",
                },
                labels: ["Progress"]
            }, 
            
            series2: [24],
            chartOptions2: {
                chart: {
                height: 120,
                type: "radialBar"
                },
                
            
                colors:["#ff5c33"],
                plotOptions: {
                radialBar: {
                    hollow: {
                    margin: 0,
                    size: "40%"
                    },
                
                    dataLabels: {
                    show: true,
                    name: {
                        offsetY: 0,
                        show: false,
                        color: "green",
                        fontSize: "13px"
                    },
                    value: {
                        offsetY: 6,
                        color: "#828d99",
                        fontSize: "15px",
                        show: true,
                        fontWeight: 700,
                    }
                    }
                }
                },
            
                stroke: {
                lineCap: "round",
                },
                labels: ["Progress"]
            },



            totalItemCount: 0,
            totalItemCount_adp: 0,
            quicksearch: '',
            pagingOptions: {},
            pagingOptions_adp: {},
            selected: [],
            baseData: [],
            baseData_adp: [],
            select_field: null,
            select_operator: null,
            filtercriteria: null,
            filterfields: [],                    
            filtersVisible: true,
            headers: [
                {text: 'Event ID', align: 'start', value: 'id',  sortable: false},
                {text: 'Adpoint ID', align: 'start', value: 'adpointid',  sortable: false},
                // {text: 'Network', align: 'start', value: 'network',  sortable: false},                
                {text: 'Type', value: 'eventtype',  sortable: false},
                {text: 'Campaign', value: 'campaign',  sortable: false},
                // {text: 'Network', value: 'networkId',  sortable: false},                
                {text: 'Status', value: 'offline',  sortable: false},
                {text: 'Extra Data', value: 'data',  sortable: false},
                {text: 'Creation (UTC)', value: 'aud_create_date',  sortable: false},                                                
            ],
            headers_adp: [
                {text: 'Adpoint ID', align: 'start', value: 'adpointid',  sortable: false},                            
                // {text: 'Device Type', value: 'deviceId',  sortable: false},
                // {text: 'Capacity', value: 'nominalCapacity'},
                // {text: '', value: 'usedCapacity'},
                // {text: 'Status', value: 'status',  sortable: false},
                {text: 'Events Count', value: 'blob'},                
                // {text: '"Click" Events Count', value: 'clickeventscount'},
                {text: '', value: 'action'}                
            ]
        }
    },
    filters: {
        formatDate(value) {
            return moment.utc(value).toISOString().replace('T', ' ').replace('Z', ' ');
        }
    },
    watch: {
      pagingOptions: {
        handler () {
          this.fetchGridData();
        },
        deep: true,
      },
      selectedCampaign() {
        this.fetchSelectedCampaignStatistics(this.selectedCampaign);
        this.fetchCampaignActivityAdpoints(this.selectedCampaign);
      },
      selectedCalcInterval() {
        this.fetchCampaignActivityAdpoints(this.selectedCampaign);
      },
      pagingOptions_adp: {
        handler () {
          this.fetchCampaignActivityAdpoints(this.selectedCampaign);
          //this.fetchGridData_adp();
        },
        deep: true,
      },
    //   quicksearch: function() {
    //       this.fetchGridData();
    //   }
    },
    computed: {
        gridData() {
            const rspData = this.baseData;
            return this.formatListData(rspData);
        },
        gridData_adp() {
            const rspData = this.baseData_adp;
            return rspData;//this.formatListData(rspData);
        },
        filterColumnCount() {
            const d = this.filtersVisible;
            return (d ? 2 : 0);
        },
        gridColumnCount() {
            const d = this.filtersVisible;
            return (d ? 10 : 12);
        },
    },
    methods: {
        async fetchSelectedCampaignStatistics(selectedCampaign) {
            if(!selectedCampaign) return;
            this.progressStatsLoading = true;
            let rsp = await api.campaign.getCampaignProgressStatistics(selectedCampaign);            
            this.progressStats = rsp.data;
            this.progressStatsLoading = false;
            //console.log('MUST RE-FETCH STATS FOR CAMPAIGN: ' + selectedCampaign);
        },
        async fetchCampaignActivityAdpoints(selectedCampaign) {
            if(!selectedCampaign) return;
            this.activityStatsLoading = true;
            let qs = '?' + this.convertSelectedCalcIntervalToQuerystringDates();
            let rsp = await api.campaign.getCampaignActivityAdpoints(selectedCampaign, qs);
            //this.progressStats = rsp.data;
            this.baseData_adp = rsp.data; 
            // rsp.data.data.map(d => { return {...d, 
            //                                             //fullname: d.firstName + ' ' + d.lastName
            //                                         } }); 
            this.totalItemCount_adp = rsp.data.total;
            this.activityStatsLoading = false;
            //console.log('MUST RE-FETCH STATS FOR CAMPAIGN: ' + selectedCampaign);
        },
        generateRandomInteger(min, max)  {
            const diff = max - min;            
            return Math.floor(min + (Math.random() * diff));
        },
        getEventActivityGraphData(dailyData) {
            let serializedData = [];
            let reduced = [];
            if(dailyData) {
                for(let rule of dailyData) {
                serializedData = serializedData.concat(...rule.graphData);
                }
                reduced = serializedData.reduce(function(pV, cV, cI){
                    let existingItem = pV.find(a=>a.refdate == cV.tgtdate);
                    if(existingItem) {
                        existingItem['actual_amount'] += parseInt(cV['actual_amount']);
                    }
                    else {
                        let newItem = {refdate: cV.tgtdate, actual_amount: parseInt(cV.actual_amount)};
                        pV.push(newItem);
                    }
                    return pV;
                }, []);
            }
            let [start,end] = this.convertSelectedCalcIntervalToQuerystringDates(true);

            let rslt = {
                series: [
                    {
                    name: 'impression',
                    data: [...reduced.map(r=> { if(r.refdate <= end && r.refdate >= start) return r.actual_amount})], //[14, 29, 18, 30, 20, 40, 36],
                    },
                    // {
                    // name: 'click',
                    // data: [null, null, null, null, null, null, null],
                    // },
                ],
                chartOptions: {
                    chart: {
                    type: 'line',
                    height: 350,
                
                    toolbar: {
                        show: false,
                    },
                    },
                    dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 1,
                    },
                    colors: ['#5d78ff', '#fbaf0f', '#FF5CD3', '#444CF2', '#48DBBB', '#A6F244', '#FBAF0F'],
                    stroke: {
                    width: 7,
                    curve: 'smooth',
                    },

                    xaxis: {
                    categories: [...reduced.map(r=>{ if(r.refdate <= end && r.refdate >= start) return r.refdate })],//['Thu 30/06', 'Fri 01/07', 'Sat 02/07', 'Sun 03/07', 'Mon 04/07', 'Tue 05/07', 'Wed 06/07'],
                    title: {
                        text: '',
                    },
                    axisBorder: {
                        show: false,
                        },
                    },
                    yaxis: {
                    title: {
                        text: '',
                    },
                    min: 0,
                    // max: 40,
                    },
                    markers: {
                    size: 5,
                    hover: {
                        size: 7,
                    },
                    },
                    grid: {
                    
                    borderColor: 'transparent',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0,
                    },
                    },
                    legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5,
                    },
                },
            }
            return rslt;
        },
        getGoalTrackingGraphData(dailyData) {
            let actualTimeSeries = [];
            let targetTimeSeries = [];
            let targetDates = [];
            if(dailyData && dailyData.graphData) {
                actualTimeSeries = dailyData.graphData.map(g=>g.actual_amount_running_total);
            }
            if(dailyData && dailyData.graphData) {
                targetTimeSeries = dailyData.graphData.map(g=>g.target_amount_running_total);
            }
             if(dailyData && dailyData.graphData) {
                targetDates = dailyData.graphData.map(g=>g.tgtdate);
            }

            let rslt = {                
                series: [
                    {
                    name: 'Actual',
                    data: actualTimeSeries //[0, 200, 213*2 + 15, 213*3 + 70, 213*4 - 43, 213*5 - 190, 213*6 - 84, 213*7 - 170, 213*8 - 250],
                    },
                    {
                    name: 'Goal',
                    data: targetTimeSeries//[0, 213, 213*2, 213*3, 213*4, 213*5, 213*6, 213*7, 213*8, 213*9, 213*10, 213*11, 213*12, 213*13, 213*14, 213*15, null, null, null, null, null],
                    },
                ],
                chartOptions: {
                    chart: {
                            height: 350,
                            type: 'line',
                            dropShadow: {
                                enabled: true,
                                color: '#000',
                                top: 18,
                                left: 7,
                                blur: 10,
                                opacity: 0.2,
                            },
                            toolbar: {
                                show: false,
                            },
                            },
                            colors: ['#28C76E', '#8e8da2'],
                            stroke: {
                            width: [4, 3],
                            curve: 'straight',
                            },
                            markers: {
                            size: [6, 0],
                            },
                            dataLabels: {
                            enabled: false,
                            },
                            grid: {
                            strokeDashArray: 3,
                            },
                            xaxis: {
                            categories: targetDates,
                            axisBorder: {
                                show: false,
                            },
                            axisTicks: {
                                show: false,
                            },
                        },                              
                },
            }
            return rslt;
        },
        getRatioWidgetData(actual, target) {
            let ratio = Math.floor(actual / target * 100);   
                        
            let initData = {};
            initData = {
                    series: [ratio],
                    chartOptions: {
                        chart: {
                        height: 120,
                        type: "radialBar"
                        },                                    
                        colors:["#33cc33"],
                        plotOptions: {
                        radialBar: {
                            hollow: {
                            margin: 0,
                            size: "40%"
                            },                    
                            dataLabels: {
                            show: true,
                            name: {
                                offsetY: 0,
                                show: false,
                                color: (ratio >= 0 ? "green" : "red"),
                                fontSize: "13px"
                            },
                            value: {
                                offsetY: 6,
                                color: "#828d99",
                                fontSize: "15px",
                                show: true,
                                fontWeight: 700,
                            }
                            }
                        }
                        },
                    
                        stroke: {
                        lineCap: "round",
                        },
                        labels: ["Progress"]
                    }
                }
            
            return initData;            
        },
        onDeleteCondition(attrNameToDelete) {
            this.filterfields = this.filterfields.filter(f=>f.value != attrNameToDelete);
        },
        addCondition() {
            const selectedAttributeData = this.attributeOptions.find(o=>o.value==this.attributeToAdd);
            if(!selectedAttributeData) 
                return;            
            this.filterfields.push({
                value: selectedAttributeData.extra.name,
                text: selectedAttributeData.extra.description || selectedAttributeData.extra.name,
                criteria: {},
                type: selectedAttributeData.extra.type.startsWith('tag') ? "list" : "text",
                listItems: selectedAttributeData.extra.valuelist.map(i=> {return {value: i, text: i}})
            });  
            this.attributeToAdd = null;          
        },
        getOptionDescription(optionList, optionValue){
            let matchedItem = optionList.find(o=>o.value===optionValue);
            let rslt = optionValue
            if(matchedItem) {
                rslt = matchedItem.text;
            }
            return rslt;
        },
        formatListData(rspData) {
            const d = rspData.map(d => { 
                return {
                    ...d, 
                    fullname: d.firstName + ' ' + d.lastName,                    
                } })
            return d;
        },
        pad(num, size) {
            var s = "000000000" + num;
            return s.substr(s.length-size);
        },
  
        getQueryDateString(srcDate) {
            return this.pad(srcDate.getFullYear(),4) + "-" + this.pad(srcDate.getMonth()+1, 2) + "-" + this.pad(srcDate.getDate(), 2) ;
        },
        convertSelectedCalcIntervalToQuerystringDates(returnDates=false) {
            let today = new Date();
            let currentDate = new Date(today.setDate(today.getDate() + 1).valueOf());    
            let oldDate = new Date(currentDate.valueOf());
            // if(this.selectedCalcInterval=='Custom Date Range') {
            //     return "calculationDate=" + this.calculationDate;
            // }
            // else 
            if (this.selectedCalcInterval=='Last 30 Days') {                
                oldDate.setDate(oldDate.getDate()-30);                
            }
            else if (this.selectedCalcInterval=='Last 20 Days') {
                oldDate.setDate(oldDate.getDate()-20);  
            }
            else if (this.selectedCalcInterval=='Last 15 Days') {
                oldDate.setDate(oldDate.getDate()-15);  
            }
            else if (this.selectedCalcInterval=='Last 10 Days') {
                oldDate.setDate(oldDate.getDate()-10);  
            }
            else if (this.selectedCalcInterval=='Last 7 Days') {
                oldDate.setDate(oldDate.getDate()-7);  
            }
            else if (this.selectedCalcInterval=='Last 5 Days') {
                oldDate.setDate(oldDate.getDate()-5);  
            }
            else if (this.selectedCalcInterval=='Last 3 Days') {
                oldDate.setDate(oldDate.getDate()-3);  
            }
            else if (this.selectedCalcInterval=='Yesterday') {
                currentDate.setDate(oldDate.getDate()-1);
                oldDate.setDate(oldDate.getDate()-1);  
            }
            if(!returnDates) {
                return "startdate=" + this.getQueryDateString(oldDate) + "&enddate=" + this.getQueryDateString(currentDate);
            }
            else {
                return [this.getQueryDateString(oldDate), this.getQueryDateString(currentDate)];
            }            
        },
        async fetchGridData() {  
            if(!this.selectedCampaign) {
                return;
            }          
            let qstr = "";
            //paging
            console.log("\nPAGING PAGING PAGING PAGING \n" + JSON.stringify(this.pagingOptions));
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagingOptions;
            const limit = itemsPerPage;
            const currentPage = page;
            qstr += "?currentPage=" + page + "&limit=" + limit;
            //sorting       
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            for(let i=0; i<sortBy.length; i++) {                
                qstr += "&sortBy[" + i + "]=" + sortBy[i];                
            }
            //sorting                   
            for(let i=0; i<sortDesc.length; i++) {                
                qstr += "&sortDesc[" + i + "]=" + sortDesc[i];                
            }     
            //criteria
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            if(this.filtercriteria && this.filtercriteria.qs) {                
                qstr += this.filtercriteria.qs;
            }      
            //quicksearch
            if(this.quicksearch && this.quicksearch.length > 2){
                //if(!qstr.includes("?")) qstr = "?" + qstr;
                qstr += "&quicksearch=" + encodeURIComponent(this.quicksearch);
            } 
            qstr += "&campaignid=" + encodeURIComponent(this.selectedCampaign);     
            //alert(querystr);
            const rsp = await api.event.getList(qstr);                  
            this.baseData = rsp.data.data.map(d => { return {...d, 
                                                        //fullname: d.firstName + ' ' + d.lastName
                                                    } }); 
            this.totalItemCount = rsp.data.total;
        },
        async fetchGridData_adp() {    
            return;        
            let qstr = "";
            //paging
            console.log("\nPAGING PAGING PAGING PAGING \n" + JSON.stringify(this.pagingOptions_adp));
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagingOptions_adp;
            const limit = itemsPerPage;
            const currentPage = page;
            qstr += "?currentPage=" + page + "&limit=" + limit;
            //sorting       
            //if(!qstr.includes("?")) qstr = "?" + qstr;
            for(let i=0; i<sortBy.length; i++) {                
                qstr += "&sortBy[" + i + "]=" + sortBy[i];                
            }
            //sorting                   
            for(let i=0; i<sortDesc.length; i++) {                
                qstr += "&sortDesc[" + i + "]=" + sortDesc[i];                
            }     
            // //criteria
            // //if(!qstr.includes("?")) qstr = "?" + qstr;
            // if(this.filtercriteria && this.filtercriteria.qs) {                
            //     qstr += this.filtercriteria.qs;
            // }      
            // //quicksearch
            // if(this.quicksearch && this.quicksearch.length > 2){
            //     //if(!qstr.includes("?")) qstr = "?" + qstr;
            //     qstr += "&quicksearch=" + encodeURIComponent(this.quicksearch);
            // }      
            // //alert(querystr);
            const rsp = await api.adpoint.getList(qstr);
            this.baseData_adp = rsp.data.data.map(d => { return {...d, 
                                                        //fullname: d.firstName + ' ' + d.lastName
                                                    } }); 
            this.totalItemCount_adp = rsp.data.total;
        },
        // toggleFilterView() {
        //     this.filtersVisible = ! this.filtersVisible
        // },
        onSearch() {
            //alert(JSON.stringify(this.filtercriteria, null, 2));
            //alert(JSON.stringify(this.filtercriteria.qs, null, 2));
            this.fetchGridData();
        },
        triggerQuicksearch() {
            // if (this.quicksearch && this.quicksearch.length > 2) {
            //     this.fetchGridData();
            // }      
            this.fetchGridData();       
        }
    },
    async created() {
        const rsp = await api.adpoint.getInitData();
        const rsp2 = await api.campaign.getOptionsRefList();
        this.campaignOptions = rsp2.data;
        const latestReleasedCampaign = rsp2.data.find(c=>c.text.includes('released'));
        if(latestReleasedCampaign) {
            //this.selectedCampaign = latestReleasedCampaign.value;
        }        
        else {
            if(rsp2.data.length>0) {
                this.selectedCampaign = rsp2.data[0].value;
            }
        }
        //this.networkOptions = rsp.data.networkOptions;
        this.networkOptions = [
            // {
            //     value: "10-220703-P11915-BG-TVS-TVC-SAMSUNG_TVS_SUP-homepage",
            //     text: "10-220703-P11915-BG-TVS-TVC-SAMSUNG_TVS_SUP-homepage",
            // },
            // {
            //     value: "10-220703-P12109-GENERIC-VARIOUS-BNR-NL-TVC-GDN-FB-PUSH-ALL_IFC-homepage",
            //     text: "10-220703-P12109-GENERIC-VARIOUS-BNR-NL-TVC-GDN-FB-PUSH-ALL_IFC-homepage",
            // },
            // {
            //     value: "4-220528-P11417-COMPUTING-MONITORS-BNR-NLPR-DELL-homepage",
            //     text: "4-220528-P11417-COMPUTING-MONITORS-BNR-NLPR-DELL-homepage",
            // },
            // {
            //     value: "6-220607-P11910-GENERIC-VARIOUS-BNR-TVC-VBR-GDN-FB-PUSH-LANDING_ALLAZW_SYSKEVI-homepage",
            //     text: "6-220607-P11910-GENERIC-VARIOUS-BNR-TVC-VBR-GDN-FB-PUSH-LANDING_ALLAZW_SYSKEVI-homepage",
            // },
            // {
            //     value: "52-220429-P11481-GENERIC-VARIOUS-LANDING_DC_V2-homepage",
            //     text: "52-220429-P11481-GENERIC-VARIOUS-LANDING_DC_V2-homepage",
            // },
            // {
            //     value: "10-220704-P12110-GENERIC-VARIOUS-BNR-TVC-PUSH-BUZZ_BAZAAR-homepage",
            //     text: "10-220704-P12110-GENERIC-VARIOUS-BNR-TVC-PUSH-BUZZ_BAZAAR-homepage",
            // },
            // {
            //     value: "Alpha Bonus - Lenovo Laptop|| July`22 - NL-homepage",
            //     text: "Alpha Bonus - Lenovo Laptop|| July`22 - NL-homepage",
            // },
            // {
            //     value: "GO_4MORE_X2-homepage",
            //     text: "GO_4MORE_X2-homepage",
            // },
        ]
        this.deviceTypeOptions = rsp.data.deviceTypeOptions;
        this.brandOptions = rsp.data.brandOptions;
        this.attributeOptions = rsp.data.attributeOptions.map(i=> { return {...i, text: '['+i.value+'] ' + i.text}});
        if(this.networkOptions.length>0){
            this.selectedNetwork = this.networkOptions[0].value
        }        


        this.filterfields.push(
            {
                value: "brandId",
                text: "Brand",
                criteria: {},
                type: "list",
                listItems: this.brandOptions
            }
        );

        this.filterfields.push(
            {
                value: "deviceId",
                text: "Device Type",
                criteria: {},
                type: "list",
                listItems: this.deviceTypeOptions
            },
        );







        this.fetchGridData();
        this.fetchGridData_adp();
        // const rsp = await api.users.getList("");
        // this.baseData = rsp.data.data.map(d => { return {...d, fullname: d.firstName + ' ' + d.lastName} }); 
        // this.baseData = [{
        //             img: require('@/assets/images/avatars/001-man.svg'),
        //             name: 'Jhon Doe',
        //             calories: 'Walmart',
        //             fat: '95',
        //             carbs: '$14,384',
        //             protein: '03/24/2020',
        //             badge: 'Active'
        //         },
        //         {
        //             img: require('@/assets/images/avatars/002-woman.svg'),
        //             name: 'Elizabeth Doe',
        //             calories: 'KFC',
        //             fat: '876',
        //             carbs: '$38,384',
        //             protein: '03/24/2020',
        //             badge: 'Deactive'
        //         },]
        //console.log("DBGINDICATOR_ " + JSON.stringify(XYZ, null, ''));
    },
    async mounted() {
        let ff = this.filterfields.find(i=>i.selectvalue === "brand");        
        if(ff) {
            const rsp = await api.brand.getAll();            
            ff.listItems = rsp.data.map(i=> {return {selectvalue: i.code, selecttext: i.title}});
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
